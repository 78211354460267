import { z } from 'zod';

export type AnyRecord = {
  [x: string]: any;
};

export type Expect<T extends true> = T;

export type AssertMutuallyAssignable<T, U> = T extends U ? true : false;

export type ReportError<T> = T extends true ? true : T;

export type VersionedSchemaOptions<
  T extends z.ZodType<any> = z.ZodType<any>,
  TFrom extends VersionedSchemaExport<any> | undefined = undefined
> =
  TFrom extends VersionedSchemaExport<any>
    ? {
        version: number;
        schema: T;
        previous: TFrom;
        migration: VersionedSchemaMigrationFn<TFrom, VersionedSchemaExport<T>>;
      }
    : {
        version: number;
        schema: T;
      };

export type VersionedSchemaExport<
  T extends z.ZodType<any> = z.ZodType<any>,
  TFrom extends VersionedSchemaExport<any> = any
> = {
  version: number;
  key: string;
  schema: z.ZodObject<{
    version: z.ZodNumber;
    key: z.ZodString;
    data: T;
  }>;
  _dataSchema: T;
  previous?: TFrom;
  migration?: VersionedSchemaMigrationFn<TFrom, VersionedSchemaExport<any>>;
};

export type VersionedSchemaMigrationFn<
  TFrom extends VersionedSchemaExport<any>,
  UTo extends VersionedSchemaExport<any>
> = (data: GetVersionedSchemaDataType<TFrom>) => GetVersionedSchemaDataType<UTo>;

export function createVersionedSchema<
  T extends z.ZodType<any>,
  TFrom extends VersionedSchemaExport<any, any> = any
>(
  key: string,
  { schema, version, previous, migration }: VersionedSchemaOptions<T, TFrom>
): VersionedSchemaExport<T, any> {
  const previousVersion = previous?.version;

  if (previousVersion) {
    if (previousVersion === version) {
      throw new Error('Previous version cannot be the same as current version');
    }

    if (previousVersion > version) {
      throw new Error('Previous version cannot be greater than current version');
    }
  }

  const versionedSchema = z.object({
    version: z.number(),
    key: z.string(),
    data: schema
  });

  return {
    version,
    schema: versionedSchema,
    previous,
    _dataSchema: schema,
    key,
    migration
  };
}

export type GetVersionedSchemaType<T extends VersionedSchemaExport<any>> = z.infer<T['schema']>;

export type GetVersionedSchemaDataType<T extends VersionedSchemaExport<any>> = z.infer<T['_dataSchema']>;

const COMMON_RXDB_COLLECTION_PAYLOAD_SCHEMA = {
  type: 'object'
} as const;

const COMMON_RXDB_COLLECTION_SUB_SCHEMA_VERSION = {
  type: 'number'
} as const;

const COMMON_RXDB_COLLECTION_SUB_SCHEMA_INVALID = {
  type: 'boolean'
} as const;

const COMMON_RXDB_COLLECTION_SUB_SCHEMA_KEY = {
  type: 'string'
} as const;

export const COMMON_RXDB_COLLECTION_WITH_SUB_SCHEMA = {
  subSchemaVersion: COMMON_RXDB_COLLECTION_SUB_SCHEMA_VERSION,
  subSchemaKey: COMMON_RXDB_COLLECTION_SUB_SCHEMA_KEY,
  subSchemaInvalid: COMMON_RXDB_COLLECTION_SUB_SCHEMA_INVALID,
  payload: COMMON_RXDB_COLLECTION_PAYLOAD_SCHEMA
};

export type CollectionWithSchemaPayload<P extends AnyRecord = AnyRecord> = {
  subSchemaVersion?: number;
  subSchemaKey?: string;
  subSchemaInvalid?: boolean;
  payload?: P;
};

export const COLLECTION_MIGRATION_STRATEGY = {
  PAYLOAD: 'payload-based',
  SNAPSHOT: 'snapshot'
} as const;

export type CollectionMigrationStrategy =
  (typeof COLLECTION_MIGRATION_STRATEGY)[keyof typeof COLLECTION_MIGRATION_STRATEGY];
