import { NotificationCellRenderer } from './notifications.cell-renderers';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import { PrimaryAlertTypeTemp } from './notifications.contracts';
import type { NotificationRow } from '@oms/generated/frontend';

// TODO: The priority should be ranked. Currently we're getting an integer from the BE.
// const priorityComparator: ColDef['comparator'] = (valueA: Priority, valueB: Priority) => {
//   const order = [Priority.Top, Priority.High, Priority.Normal];
//   return order.indexOf(valueA) - order.indexOf(valueB);
// };

const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: true
};

// Unhide columns for dev
const hidden = true;

export const notificationColumns: ColDefMap<NotificationRow> = {
  id: { extends: 'orders.id', hide: hidden },
  name: { extends: 'generic.text', hide: hidden },
  priority: { extends: 'generic.text', hide: hidden },
  feedback: { extends: 'generic.text', hide: hidden },
  targetObjectType: { extends: 'generic.text', hide: hidden },
  targetObjectId: { extends: 'orders.id', hide: hidden },
  targetObjectPayload: { extends: 'generic.text', hide: hidden },
  sourceEventType: { extends: 'generic.text', hide: hidden },
  vsourceEventId: { extends: 'orders.id', hide: hidden },
  sourceEventTimestamp: { extends: 'generic.dateTime', hide: hidden },
  notificationCommonId: { extends: 'orders.id', hide: hidden },
  addressedUserId: { extends: 'orders.id', hide: hidden },
  isSoundPlayed: { extends: 'generic.text', hide: hidden },
  isPopupLaunched: { extends: 'generic.text', hide: hidden },
  isDisplayed: { extends: 'generic.text', hide: hidden },
  primaryAlertType: {
    extends: 'generic.text',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(PrimaryAlertTypeTemp),
      valueFormatter: 'primaryAlertType'
    },
    valueFormatter: 'primaryAlertType',
    hide: hidden
  },
  content: {
    extends: 'generic.text',
    cellRenderer: NotificationCellRenderer,
    flex: 1
  }
};

export const notificationsColumnLibrary: TableServerColumnLibrary<NotificationRow> = {
  defaultColumn,
  columns: notificationColumns
};

export const buildNotificationsColumnDefs = () =>
  buildColumnDefs(notificationsColumnLibrary, 'notificationCenter');
