import { registerComponent, COMMON_OBJECT_CATEGORY } from '@app/app-config/registry.config';
import { LATEST_VERSIONED_SCHEMA } from './schema.positions-account-grid.widget';

const config = registerComponent({
  key: 'POSITIONS_ACCOUNT_GRID',
  title: 'Positions Account Grid',
  objectCategory: COMMON_OBJECT_CATEGORY.TRADING,
  versionedSchema: LATEST_VERSIONED_SCHEMA,
  showInCommandPalette: false,
  exludeFromDictionary: true
});

export default config;
