import type { ComponentType } from 'react';
import { withUpdatedData, useUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { InvestorOrdersSubscriptionService } from '@app/data-access/services/trading/investor-orders/investor-orders.subscriptions.service';
import type { InvestorOrderRow } from '@oms/generated/frontend';

export type UpdatedInvestorOrder = UpdatedData<InvestorOrderRow>;

export const withUpdatedInvestorOrder = (
  Component: ComponentType<UpdatedInvestorOrder>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<InvestorOrderRow>(Component, InvestorOrdersSubscriptionService, props);
};

export const useUpdatedInvestorOrder = (id: string | null | undefined) => {
  return useUpdatedData<InvestorOrderRow>(InvestorOrdersSubscriptionService, id);
};
