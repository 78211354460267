import type {
  HighLevelRequestMethod,
  HighLevelRequestResult,
  JobObservable,
  RequestResult
} from '@fds/frontgate-js-sdk';
import type { paths as FactsetPaths } from './factset.openapi.types';
import { FactsetClient } from './factset.client';
import { Level1IntegrationEvent, Maybe, Scalars } from '@oms/generated/frontend';

type FactsetKeyOf<T> = keyof T;
export type FactsetEndpoints = FactsetKeyOf<FactsetPaths>;
export type FactsetEndpointMethods<T extends FactsetEndpoints> = FactsetKeyOf<FactsetPaths[T]>;
type FactsetOperations<TEndpoint extends FactsetEndpoints> =
  FactsetPaths[TEndpoint][FactsetEndpointMethods<TEndpoint>];
export type FactsetOperationRequest<
  TEndpoint extends FactsetEndpoints,
  TRequests extends FactsetKeyOf<FactsetOperations<TEndpoint>>
> = FactsetOperations<TEndpoint>[TRequests];
export type FactsetOperationResult<
  TEndpoint extends FactsetEndpoints,
  TResponses extends FactsetKeyOf<FactsetOperations<TEndpoint>>,
  TStatusCode extends FactsetKeyOf<FactsetOperations<TEndpoint>[TResponses]>
> = FactsetOperations<TEndpoint>[TResponses][TStatusCode];

// source object path
// dest object path
export type FactsetMapping<TResult> = [string, keyof TResult];

export type FactsetPricesByTypeRequest = FactsetOperationRequest<
  '/prices/getByType',
  'requestBody'
>['content']['application/json'];
export type FactsetPricesByTypeResponse = Required<
  FactsetOperationResult<'/prices/getByType', 'responses', 200>['content']['application/json']
>['data'];

export type FactsetPropertyListRequest = NonNullable<
  FactsetOperationRequest<'/prices/property/list', 'requestBody'>
>['content']['application/json'];
export type FactsetPropertyListResponse = Required<
  FactsetOperationResult<'/prices/property/list', 'responses', 200>['content']['application/json']
>;
export type FactsetPriceRequest = FactsetOperationRequest<'/prices/get', 'parameters'>['query'];
export type FactsetPriceResponse = Required<
  FactsetOperationResult<'/prices/get', 'responses', 200>['content']['application/json']
>['data'];
export type FactsetNotationStatusRequest = FactsetOperationRequest<
  '/notation/status/get',
  'parameters'
>['query'];
export type FactsetNotationStatusResponse = Required<
  FactsetOperationResult<'/notation/status/get', 'responses', 200>['content']['application/json']
>['data'];
export type FactsetKeyFigureYtdRequest = FactsetOperationRequest<
  '/notation/keyFigures/yearToDate/get',
  'parameters'
>['query'];
export type FactsetKeyFigureYtdResponse = Required<
  FactsetOperationResult<
    '/notation/keyFigures/yearToDate/get',
    'responses',
    200
  >['content']['application/json']
>['data'];
export type FactsetKeyFigure52WkRequest = FactsetOperationRequest<
  '/notation/keyFigures/year/1/get',
  'parameters'
>['query'];
export type FactsetKeyFigure52WkResponse = Required<
  FactsetOperationResult<'/notation/keyFigures/year/1/get', 'responses', 200>['content']['application/json']
>['data'];
export type FactsetKeyFigureWklyRequest = FactsetOperationRequest<
  '/notation/keyFigures/week/1/get',
  'parameters'
>['query'];
export type FactsetKeyFigureWklyResponse = Required<
  FactsetOperationResult<'/notation/keyFigures/week/1/get', 'responses', 200>['content']['application/json']
>['data'];

export type FactsetKeyFigureMnthlyRequest = FactsetOperationRequest<
  '/notation/keyFigures/month/1/get',
  'parameters'
>['query'];
export type FactsetKeyFigureMnthlyResponse = Required<
  FactsetOperationResult<'/notation/keyFigures/month/1/get', 'responses', 200>['content']['application/json']
>['data'];

export interface FactsetMapConfig<R> {
  endpoint: FactsetEndpoints;
  mappings: FactsetMapping<R>[];
  result?: R;
  logMissingFields?: EndpointFilter;
}

export interface FactsetObserverConfig<TRequest> {
  method: HighLevelRequestMethod;
  endpoint: FactsetEndpoints;
  payload: TRequest;
}

export interface FactsetRequestResult<T> extends RequestResult {
  data: T;
}

export interface FactsetHighLevelRequestResult<T> extends HighLevelRequestResult {
  data: T;
}

export type EndpointFilter = boolean | Array<FactsetEndpoints>;

export interface MarketDataConfig<T = any> {
  ticker: string;
  /**
   * Amount of time (ms) to wait before sending the latest value within the time window.
   */
  auditTime?: number;
  client: FactsetClient;
  /**
   * Logs any retry errors and what the backoff time is. True for all endpoints, or an array of endpoints to log.
   */
  logRetries?: EndpointFilter;
  /**
   * Logs what fields were not able to be mapped from a factset response. True for all endpoints, or an array of endpoints to log.
   */
  logMissingFields?: EndpointFilter;
  /**
   * Log the raw payloads from factset. True for all endpoints, or an array of endpoints to log.
   */
  logFactsetResponse?: EndpointFilter;
  result?: T;
}

export interface FactsetFinalizeConfig<T = any> {
  job: JobObservable<T>;
  endpoint: FactsetEndpoints;
  client: FactsetClient;
}

// From: https://endpointreference.factset.com/swagger/4885152708/v1/(ep-outlet:endpoint/post/%2Fprices%2Ftype%2Flist)
// Also maintained as a Java enum on the backend. See: PricesByType.java
export const PricesByType = {
  ASK: 2,
  AUCTION: 7,
  AUTOMATIC: 103,
  BID: 4,
  CROSS_RATE: 88,
  EX_CLOSE: 234,
  FIXING: 99,
  GENERAL: 100,
  INAV: 23,
  LOWER_DYNAMIC_THRESHOLD: 243,
  LOWER_STATIC_THRESHOLD: 241,
  MID: 128,
  NAV: 22,
  ODD_LOT_TRADE: 163,
  OFFICIAL_CLOSE: 9,
  OFFICIAL_CLOSING_ASK: 194,
  OFFICIAL_CLOSING_BID: 193,
  OFFICIAL_HIGH: 10,
  OFFICIAL_LOW: 11,
  OFFICIAL_MID: 169,
  OFFICIAL_OPEN: 8,
  OFFICIAL_TRADE: 1,
  OPEN_INTEREST: 75,
  POST_TRADING: 45,
  PRE_TRADING: 46,
  REDEMPTION: 20,
  REFERENCE: 50,
  SETTLEMENT: 77,
  TOTAL: 6,
  TRADE: 208,
  UPPER_DYNAMIC_THRESHOLD: 242,
  UPPER_STATIC_THRESHOLD: 240,
  VWAP: 48
} as const;

export type RawLevel1IntegrationEvent = Omit<Level1IntegrationEvent, 'propertyIds' | 'trdCond'> & {
  propertyIds?: Maybe<{ id: number }[]>;
  trdCond?: Maybe<{ shortName: string; id: number }[]>;
};

export type Level1FactsetWithFallbackFields = Level1IntegrationEvent & {
  officialLowDayPrice?: Maybe<Scalars['Float']>;
  officialHighDayPrice?: Maybe<Scalars['Float']>;
  officialOpenDateTime?: Maybe<Scalars['LocalDateTime']>;
  officialOpenPrice?: Maybe<Scalars['Float']>;
  officialClosePrice?: Maybe<Scalars['Float']>;
  officialCloseDateTime?: Maybe<Scalars['LocalDateTime']>;
  officialCloseVolume?: Maybe<Scalars['Int']>;
  previousExCloseDateTime?: Maybe<Scalars['LocalDateTime']>;
  previousExClosePrice?: Maybe<Scalars['Float']>;
};
