import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionComponentConfig, ActionContext } from '@oms/frontend-vgrid';
import { openRouteInvestorOrder } from '../../route-order/route-order.form-open';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

type RouteInvestorOrderActionArgs = {
  id: string;
  status: string;
  openQuantity: number;
  instrumentDisplayCode: string;
};

const isRouteInvestorOrderVisible = (rowData?: RouteInvestorOrderActionArgs[]) =>
  rowData !== undefined &&
  rowData.length === 1 &&
  !!rowData[0] &&
  rowData[0]?.status === InvestorOrderStatus.Active &&
  rowData[0]?.openQuantity !== null &&
  rowData[0]?.openQuantity !== undefined &&
  rowData[0]?.openQuantity > 0;

const isActionEnabled = (data: RouteInvestorOrderActionArgs[]) => {
  if (data.length !== 1) {
    return false;
  }

  return isRouteInvestorOrderVisible(data);
};

export const openRouteInvestorOrderOnChange = <T extends RouteInvestorOrderActionArgs>(
  e: ActionContext<T>
) => {
  const { lifecycle, data } = e;
  e.notify({ isDisabled: !isActionEnabled(data) });

  const selectedRow = data?.[0];
  const id = selectedRow?.id;
  const symbol = selectedRow?.instrumentDisplayCode;

  if (lifecycle === 'change' && id) {
    openRouteInvestorOrder(e.workspace, { id, symbol });
  }
};

export const openRouteInvestorOrderAction =
  <T extends RouteInvestorOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('open_route_investor_order')
      .toolbar((t) =>
        t
          .id('open_route_investor_order_button')
          .component('action-button')
          .location('HorizontalToolbarRight')
          .props({
            content: 'Route Order',
            isDisabled: true
          })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu((m) =>
        m
          .name('Route Order')
          .visible(({ rowData }) => isRouteInvestorOrderVisible(rowData))
          .primary()
      )
      .onChange<ActionComponentConfig<T>>(openRouteInvestorOrderOnChange);
