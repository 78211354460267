import type { DetailGridBuilder } from '@oms/frontend-vgrid';
import { TradingOrdersDetailGrid } from './investor-order-monitor.details.grid';
import type { TradingOrderDetailGridProps } from './investor-order-monitor.details.grid';
import { InvestorOrderTradingOrdersStatsGroupHeader } from '../trading-order-monitor/trading-order-monitor.stats-header';
import type { InvestorOrderRow } from '@oms/generated/frontend';

const STATS_HEADER_HEIGHT = 48;

export const investorOrderMasterDetail = (
  md: DetailGridBuilder<InvestorOrderRow, TradingOrderDetailGridProps>
) =>
  md
    .groupByColumn('instrumentDisplayCode', (d) => !!d?.tradingOrderIds?.length)
    .headerComponent(InvestorOrderTradingOrdersStatsGroupHeader)
    .detailComponent(TradingOrdersDetailGrid)
    .getDetailProps(({ data }) => ({ investorOrderId: data?.id || '' }))
    .getDetailRowHeightParams(({ data }) => {
      return {
        numberOfRows: data?.tradingOrderIds?.length,
        headerComponentHeight: STATS_HEADER_HEIGHT,
        hideToolbar: true
      };
    });
