import { z } from 'zod';
import type { infer as ZodInfer } from 'zod';
import { zOrderSideType, zStrategy, zTimeInForce } from './trading.zod.schemas';
import { PriceType, QuantityType } from '@oms/generated/frontend';
import { createPrefixingFn } from '@oms/shared/util';

// Montage Settings ------------------------------------------------------------------- /

const zPriceType: z.ZodType<PriceType> = z.nativeEnum(PriceType);

const zQuantityType: z.ZodType<QuantityType> = z.nativeEnum(QuantityType);

export type LeftClickSettingsType = 'ask' | 'bid';

export const zLeftClickSettings = z.object({
  destinationId: z.string().optional(),
  displaySize: z.number().optional(),
  gtdTimestamp: z.string().optional(),
  initiateOrder: z.boolean(),
  orderSize: z.string().optional(),
  priceType: zPriceType,
  quantityType: zQuantityType,
  quantityValue: z.number().optional(),
  sideType: zOrderSideType,
  strategy: zStrategy.optional(),
  strategyPresets: z.string().optional(),
  tifDuration: z.string().optional(),
  timeInForceType: zTimeInForce,
  timeInForceValue: z.number().optional()
});

export const zAskLeftClickSettings = z.object(
  createPrefixingFn<Extract<LeftClickSettingsType, 'ask'>>('ask')(zLeftClickSettings.shape)
);

export const zBidLeftClickSettings = z.object(
  createPrefixingFn<Extract<LeftClickSettingsType, 'bid'>>('bid')(zLeftClickSettings.shape)
);

export type LeftClickSettingsSchema<Type extends LeftClickSettingsType | undefined = undefined> =
  Type extends undefined
    ? ZodInfer<typeof zLeftClickSettings>
    : Type extends 'ask'
      ? ZodInfer<typeof zAskLeftClickSettings>
      : ZodInfer<typeof zBidLeftClickSettings>;

const zAllLeftClickSettings = zAskLeftClickSettings.merge(zBidLeftClickSettings);

export const zMontageSettings = zAllLeftClickSettings.merge(
  z.object({
    ...zAskLeftClickSettings.shape,
    ...zBidLeftClickSettings.shape,
    // Flags
    hideOddLots: z.boolean(),
    displayQuotesInShares: z.boolean(),
    sendAttributable: z.boolean(),
    // Unimplemented
    orderMappings: z.any().optional(),
    orderViewOptions: z.any().optional()
  })
);

export type MontageSettingsSchema = ZodInfer<typeof zMontageSettings>;

export const Z_MONTAGE_SETTINGS = {
  QUANTITY_TYPE: zQuantityType,
  PRICE_TYPE: zPriceType,
  LEFT_CLICK_SETTINGS: zLeftClickSettings
} as const;

// Order Settings ------------------------------------------------------------------- /

const zOrderSizeRow = z.object({
  id: z.string(),
  priceFrom: z.number(),
  priceTo: z.number(),
  orderSize: z.number()
});

const zOrderSizeProfile = z.object({
  id: z.string(),
  description: z.string(),
  rows: z.array(zOrderSizeRow).nullish()
});

export const Z_ORDER_SETTINGS = {
  ORDER_SIZE_ROW: zOrderSizeRow,
  ORDER_SIZE_PROFILE: zOrderSizeProfile
} as const;

// User Preferences ------------------------------------------------------------------- /

const zUserPreferences = zMontageSettings.deepPartial().extend({
  orderSettings: z.array(zOrderSizeProfile).optional()
});

export const Z_USER_PREFERENCES = {
  MONTAGE_SETTINGS: zMontageSettings,
  USER_PREFERENCES: zUserPreferences
} as const;
