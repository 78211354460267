import { CellBadgeClassEnum } from '@oms/frontend-vgrid';
import type { MarketDataBuilder } from '@oms/frontend-vgrid';
import { InvestorOrderStatus } from '@oms/generated/frontend';
import type { InvestorOrderRow } from '@oms/generated/frontend';
import type {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import { MarketDataCellRenderer } from '@app/data-access/services/marketdata/components/market-data.cell.renderer';
export const OWNER_ID_FIELD_NAME = 'owner.id';
export const ORDER_VISBILITY_FIELD_NAME = 'reason';
export const INVESTOR_ACCOUNT_FIELD_NAME = 'investorAccount';
export const INVESTOR_ACCOUNT_NAME_FIELD_NAME = 'investorAccount.name';
export const INVESTOR_ACCOUNT_ID_FIELD_NAME = 'investorAccount.id';

export const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: true
};

export const investorOrderMonitorColumns: ColDefMap<InvestorOrderRow> = {
  id: {
    extends: 'orders.id',
    floatingFilter: false,
    hide: true,
    filterParams: { filterOptions: ['equals'] }
  },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  instrumentLongName: { extends: 'orders.instrumentLongName' },
  instrumentCusip: { extends: 'orders.instrumentCusip' },
  instrumentExchCode: { extends: 'orders.instrumentExchCode' },
  side: { extends: 'orders.side' },
  limitPrice: { extends: 'orders.limitPrice' },
  totalQuantity: { extends: 'orders.quantity' },
  leavesQuantity: { extends: 'orders.quantity' },
  openQuantity: { extends: 'orders.quantity' },
  executedQuantity: { extends: 'orders.quantity', cellRenderer: 'ProgressRenderer' },
  workingQuantity: { extends: 'orders.quantity' },
  stopPrice: { extends: 'orders.price', hide: true },
  investorAccountName: { extends: 'orders.investorAccount' },
  investorAccountId: { extends: 'generic.text', hide: true },
  validatedTimestamp: { extends: 'generic.timestamp', sort: 'desc' },
  transmittedTimestamp: { extends: 'generic.timestamp', hide: true },
  receivedTimestamp: { extends: 'generic.timestamp', hide: true },
  orderType: { extends: 'orders.orderType' },
  commissionRateType: { width: 100, filter: false },
  commissionRateValue: { width: 100, filter: false },
  commissionAmount: { width: 100, filter: false },
  feesTotal: { width: 100, filter: false },
  marketChargeTotal: { width: 100, filter: false },
  timeInForce: { extends: 'orders.timeInForce' },
  status: {
    extends: 'orders.status',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(InvestorOrderStatus),
      valueFormatter: 'investorOrderStatus'
    },
    valueFormatter: 'investorOrderStatus',
    cellClass: CellBadgeClassEnum.Capital
  },
  todayExecutedQuantity: { extends: 'orders.todayExecutedQuantity', hide: true },
  todayAveragePrice: { extends: 'orders.price', hide: true },
  expiryDateTime: { extends: 'generic.dateTime', hide: true },
  locate: { extends: 'generic.text', hide: true },
  clientOrderId: { extends: 'orders.id' },
  orderTagIds: {
    width: 110,
    filter: false,
    sortable: false,
    hide: true
  },
  averagePrice: { extends: 'orders.price' },
  orderEntryType: { extends: 'orders.orderEntryType' },
  orderComments: { extends: 'generic.text', hide: true },
  customerNotes: { extends: 'generic.text', hide: true },
  ownerName: {
    extends: 'orders.owner'
  },
  ownerID: {
    extends: 'orders.ownerId',
    hide: true
  },
  representativeCode: { extends: 'generic.text', hide: true },
  sendingDesk: { extends: 'generic.text' },
  underlyingAccount: { extends: 'generic.text' },
  tradeCurrency: { extends: 'generic.currency', hide: true },
  settleCurrency: { extends: 'generic.currency', hide: true },
  settleType: { extends: 'orders.settleType', hide: true },
  settleDate: {
    extends: 'generic.date',
    minWidth: 170
  },
  createdTimestamp: { extends: 'generic.timestamp', hide: true },
  orderVisibilityReason: {
    extends: 'orders.visibilityReason',
    hide: true
  },
  updatedTime: { extends: 'generic.dateTime', hide: true },
  lastExecutionTime: { extends: 'generic.dateTime', hide: true },
  workflow: { extends: 'generic.text', hide: true },
  fixWorkflowId: { extends: 'generic.text', hide: true }
};

export const investorOrderMonitorColumnLibrary: TableServerColumnLibrary<InvestorOrderRow> = {
  defaultColumn,
  columns: investorOrderMonitorColumns
};

export const buildInvestorOrderMonitorColumnDefs = (
  nonFilterableFields: string[] = [],
  excludedFields: string[] = []
) => {
  const processedColumns = Object.entries(investorOrderMonitorColumnLibrary.columns).reduce(
    (acc, [colId, colDef]) =>
      excludedFields.includes(colId)
        ? acc
        : {
            ...acc,
            [colId]: { ...colDef, ...(nonFilterableFields.includes(colId) && { filter: false }) }
          },
    {}
  );

  return buildColumnDefs(
    { ...investorOrderMonitorColumnLibrary, columns: processedColumns },
    'investorOrderMonitor'
  );
};

export const investorOrderMarketData = (m: MarketDataBuilder<any>) =>
  m.level1((l) =>
    l
      .defaultField((c) =>
        c
          .minWidth(90)
          .width(100)
          .hide()
          .cell((c) => c.renderer(MarketDataCellRenderer))
      )
      .fields({
        tickerId: 'instrumentDisplayCode', // the ticker field to "join" with
        fields: [
          (c) => c.field('bidPrice').show().level1MarketData('bidPrice', 'ticker').filter(false),
          (c) => c.field('askPrice').show().level1MarketData('askPrice', 'ticker').filter(false)
        ]
      })
  );
