/* eslint-disable */
/** TABLE SERVER TYPES **/
import { gql } from '@apollo/client';

/*********************** TableServerJoinedInstrument *************************/

export type TableServerJoinedInstrument = {
  id: string;
  displayCode: string;
  longName: string;
  active: boolean;
  firmMarketMaker: boolean;
  isin: string;
  cusip: string;
  sedol: string;
  exchCode: string;
  figi: string;
  factsetTickerRegion: string;
  bbgTickerComposite: string;
  bbgTickerExchange: string;
  factsetPermanentId: string;
  adrRatio: number;
  businessSectorId: string;
  businessSectorName: string;
  countryOfIncorporationId: string;
  industryId: string;
  industryName: string;
  issueDate: string;
  listingExchangeId: string;
  parentInstrumentId: string;
  securityTypeId: string;
  securityTypeDescription: string;
  settleCurrencyId: string;
  tradedCurrencyId: string;
};


export const TsInstrumentsWithFilterDocument = gql`
  subscription OnTsInstrumentsWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsInstrumentsWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsInstrumentQueryResult {
        rows {
          id
          displayCode
          longName
          active
          firmMarketMaker
          isin
          cusip
          sedol
          exchCode
          figi
          factsetTickerRegion
          bbgTickerComposite
          bbgTickerExchange
          factsetPermanentId
          adrRatio
          businessSectorId
          businessSectorName
          countryOfIncorporationId
          industryId
          industryName
          issueDate
          listingExchangeId
          parentInstrumentId
          securityTypeId
          securityTypeDescription
          settleCurrencyId
          tradedCurrencyId
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsInstrumentPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsInstrumentsWithFilterSubscription = {
  tsInstrumentsWithFilter: {
    rows?: TableServerJoinedInstrument[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type InstrumentRow = TableServerJoinedInstrument;

/*********************** TableServerJoinedTradingOrder *************************/

export type TableServerJoinedTradingOrder = {
  id: string;
  instrument: string;
  instrumentDisplayCode: string;
  instrumentLongName: string;
  instrumentCusip: string;
  instrumentExchCode: string;
  side: string;
  locate: string;
  orderType: string;
  totalQuantity: number;
  leavesQuantity: number;
  executedQuantity: number;
  minQuantity: number;
  displayQuantity: number;
  settleCurrency: string;
  settleType: string;
  settleDate: Date;
  tradeCurrency: string;
  tradingAccount: string;
  tradingAccountName: string;
  tradingAccountType: string;
  venue: string;
  timeInForce: string;
  tifDuration: number;
  status: string;
  averagePrice: number;
  createdTimestamp: Date;
  transmittedTimestamp: Date;
  orderVisibilityReason: string;
  customerNotes: string;
  representativeCodeId: string;
  representativeCode: string;
  limitPrice: string;
  orderTagIds: string[];
  orderTagNames: string[];
  capacity: string;
  targetExchangeId: string;
  category: string;
  expiryDateTime: Date;
  enteredBy: string;
  ownerID: string;
  ownerEmail: string;
  userIDs: string[];
  ownerName: string;
  enteredByName: string;
  enteredByEmail: string;
  updatedTimestamp: Date;
  fixMessage: string;
  venueNotes: string;
  visibleSendingDesk: string;
  visibleUnderlyingAccount: string;
  isBoundOrder: boolean;
  investorOrderIds: string[];
  strategy: any;
  strategyDetails: any;
};


export const TsTradingOrdersWithFilterDocument = gql`
  subscription OnTsTradingOrdersWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsTradingOrdersWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsTradingOrderQueryResult {
        rows {
          id
          instrument
          instrumentDisplayCode
          instrumentLongName
          instrumentCusip
          instrumentExchCode
          side
          locate
          orderType
          totalQuantity
          leavesQuantity
          executedQuantity
          minQuantity
          displayQuantity
          settleCurrency
          settleType
          settleDate
          tradeCurrency
          tradingAccount
          tradingAccountName
          tradingAccountType
          venue
          timeInForce
          tifDuration
          status
          averagePrice
          createdTimestamp
          transmittedTimestamp
          orderVisibilityReason
          customerNotes
          representativeCodeId
          representativeCode
          limitPrice
          orderTagIds
          orderTagNames
          capacity
          targetExchangeId
          category
          expiryDateTime
          enteredBy
          ownerID
          ownerEmail
          userIDs
          ownerName
          enteredByName
          enteredByEmail
          updatedTimestamp
          fixMessage
          venueNotes
          visibleSendingDesk
          visibleUnderlyingAccount
          isBoundOrder
          investorOrderIds
          strategy
          strategyDetails
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsTradingOrderPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsTradingOrdersWithFilterSubscription = {
  tsTradingOrdersWithFilter: {
    rows?: TableServerJoinedTradingOrder[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type TradingOrderRow = TableServerJoinedTradingOrder;

/*********************** TableServerJoinedInvestorOrder *************************/

export type TableServerJoinedInvestorOrder = {
  id: string;
  instrument: string;
  instrumentDisplayCode: string;
  instrumentLongName: string;
  instrumentCusip: string;
  instrumentExchCode: string;
  side: string;
  locate: string;
  orderType: string;
  totalQuantity: number;
  leavesQuantity: number;
  openQuantity: number;
  executedQuantity: number;
  workingQuantity: number;
  stopPrice: number;
  investorAccountId: string;
  investorAccountName: string;
  validatedTimestamp: Date;
  receivedTimestamp: Date;
  gtdTimestamp: Date;
  commissionRateType: string;
  commissionRateValue: number;
  commissionAmount: number;
  feesTotal: number;
  marketChargeTotal: number;
  todayExecutedQuantity: number;
  todayAveragePrice: number;
  clientOrderId: string;
  orderEntryType: string;
  orderComments: string;
  sendingDesk: string;
  underlyingAccount: string;
  lastExecutionTime: Date;
  workflow: string;
  fixWorkflowId: string;
  settleCurrency: string;
  settleType: string;
  settleDate: Date;
  tradeCurrency: string;
  timeInForce: string;
  status: string;
  averagePrice: number;
  createdTimestamp: Date;
  transmittedTimestamp: Date;
  orderVisibilityReason: string;
  customerNotes: string;
  representativeCodeId: string;
  representativeCode: string;
  limitPrice: string;
  orderTagIds: string[];
  orderTagNames: string[];
  expiryDateTime: Date;
  ownerID: string;
  ownerName: string;
  ownerEmail: string;
  enteredBy: string;
  enteredByEmail: string;
  updatedTime: Date;
  investorAccountType: string;
  parentTradingAccountId: string;
  tradingOrderIds: string[];
  parentTradingOrderId: string;
  captureTimestamp: Date;
  notionalAmount: number;
};


export const TsInvestorOrdersWithFilterDocument = gql`
  subscription OnTsInvestorOrdersWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsInvestorOrdersWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsInvestorOrderQueryResult {
        rows {
          id
          instrument
          instrumentDisplayCode
          instrumentLongName
          instrumentCusip
          instrumentExchCode
          side
          locate
          orderType
          totalQuantity
          leavesQuantity
          openQuantity
          executedQuantity
          workingQuantity
          stopPrice
          investorAccountId
          investorAccountName
          validatedTimestamp
          receivedTimestamp
          gtdTimestamp
          commissionRateType
          commissionRateValue
          commissionAmount
          feesTotal
          marketChargeTotal
          todayExecutedQuantity
          todayAveragePrice
          clientOrderId
          orderEntryType
          orderComments
          sendingDesk
          underlyingAccount
          lastExecutionTime
          workflow
          fixWorkflowId
          settleCurrency
          settleType
          settleDate
          tradeCurrency
          timeInForce
          status
          averagePrice
          createdTimestamp
          transmittedTimestamp
          orderVisibilityReason
          customerNotes
          representativeCodeId
          representativeCode
          limitPrice
          orderTagIds
          orderTagNames
          expiryDateTime
          ownerID
          ownerName
          ownerEmail
          enteredBy
          enteredByEmail
          updatedTime
          investorAccountType
          parentTradingAccountId
          tradingOrderIds
          parentTradingOrderId
          captureTimestamp
          notionalAmount
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsInvestorOrderPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsInvestorOrdersWithFilterSubscription = {
  tsInvestorOrdersWithFilter: {
    rows?: TableServerJoinedInvestorOrder[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type InvestorOrderRow = TableServerJoinedInvestorOrder;

/*********************** TableServerJoinedAllocationMapInvestorOrder *************************/

export type TableServerJoinedAllocationMapInvestorOrder = {
  allocationMapId: string;
  investorOrderId: string;
  tradingOrderId: string;
  id: string;
  mappedQuantity: number;
  averagePrice: number;
  totalQuantity: number;
  executedQuantity: number;
  createdTimestamp: Date;
  locate: string;
  side: string;
  limitPrice: string;
  status: string;
  orderType: string;
  instrumentDisplayCode: string;
  todayAveragePrice: number;
  todayExecutedQuantity: number;
};


export const TsMappedInvestorOrdersWithFilterDocument = gql`
  subscription OnTsMappedInvestorOrdersWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsMappedInvestorOrdersWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsMappedInvestorOrderQueryResult {
        rows {
          allocationMapId
          investorOrderId
          tradingOrderId
          id
          mappedQuantity
          averagePrice
          totalQuantity
          executedQuantity
          createdTimestamp
          locate
          side
          limitPrice
          status
          orderType
          instrumentDisplayCode
          todayAveragePrice
          todayExecutedQuantity
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsMappedInvestorOrderPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsMappedInvestorOrdersWithFilterSubscription = {
  tsMappedInvestorOrdersWithFilter: {
    rows?: TableServerJoinedAllocationMapInvestorOrder[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type MappedInvestorOrderRow = TableServerJoinedAllocationMapInvestorOrder;

/*********************** TableServerJoinedAllocationMapTradingOrder *************************/

export type TableServerJoinedAllocationMapTradingOrder = {
  allocationMapId: string;
  investorOrderId: string;
  tradingOrderId: string;
  id: string;
  mappedQuantity: number;
  venue: string;
  totalQuantity: number;
  executedQuantity: number;
  createdTimestamp: Date;
  capacity: string;
  locate: string;
  side: string;
  averagePrice: number;
  limitPrice: string;
  status: string;
  orderType: string;
  instrument: string;
  instrumentDisplayCode: string;
  instrumentLongName: string;
  instrumentCusip: string;
  instrumentExchCode: string;
};


export const TsMappedTradingOrdersWithFilterDocument = gql`
  subscription OnTsMappedTradingOrdersWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsMappedTradingOrdersWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsMappedTradingOrderQueryResult {
        rows {
          allocationMapId
          investorOrderId
          tradingOrderId
          id
          mappedQuantity
          venue
          totalQuantity
          executedQuantity
          createdTimestamp
          capacity
          locate
          side
          averagePrice
          limitPrice
          status
          orderType
          instrument
          instrumentDisplayCode
          instrumentLongName
          instrumentCusip
          instrumentExchCode
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsMappedTradingOrderPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsMappedTradingOrdersWithFilterSubscription = {
  tsMappedTradingOrdersWithFilter: {
    rows?: TableServerJoinedAllocationMapTradingOrder[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type MappedTradingOrderRow = TableServerJoinedAllocationMapTradingOrder;

/*********************** TableServerJoinedNotification *************************/

export type TableServerJoinedNotification = {
  id: string;
  name: string;
  priority: string;
  feedback: string;
  targetObjectType: string;
  notificationType: string;
  targetObjectId: string;
  targetObjectPayload: string;
  sourceEventType: string;
  vsourceEventId: string;
  sourceEventTimestamp: string;
  notificationCommonId: string;
  addressedUserId: string;
  isSoundPlayed: string;
  isPopupLaunched: string;
  isDisplayed: string;
  primaryAlertType: string;
  content: string;
};


export const TsNotificationsWithFilterDocument = gql`
  subscription OnTsNotificationsWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsNotificationsWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsNotificationQueryResult {
        rows {
          id
          name
          priority
          feedback
          targetObjectType
          notificationType
          targetObjectId
          targetObjectPayload
          sourceEventType
          vsourceEventId
          sourceEventTimestamp
          notificationCommonId
          addressedUserId
          isSoundPlayed
          isPopupLaunched
          isDisplayed
          primaryAlertType
          content
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsNotificationPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsNotificationsWithFilterSubscription = {
  tsNotificationsWithFilter: {
    rows?: TableServerJoinedNotification[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type NotificationRow = TableServerJoinedNotification;

/*********************** TableServerMappedDailyExecutions *************************/

export type TableServerMappedDailyExecutions = {
  compositeKey: string;
  investorOrderId: string;
  orderStatus: string;
  executionDate: Date;
  executedQuantity: number;
  averagePrice: number;
  averageSettlementFxRate: number;
  instrumentId: string;
  exchangeId: string;
  lastExecutionDateTime: Date;
  lastReportGenerationDateTime: Date;
};


export const TsDailyExecutionsWithFilterDocument = gql`
  subscription OnTsDailyExecutionsWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsDailyExecutionsWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsDailyExecutionQueryResult {
        rows {
          compositeKey
          investorOrderId
          orderStatus
          executionDate
          executedQuantity
          averagePrice
          averageSettlementFxRate
          instrumentId
          exchangeId
          lastExecutionDateTime
          lastReportGenerationDateTime
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsDailyExecutionPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsDailyExecutionsWithFilterSubscription = {
  tsDailyExecutionsWithFilter: {
    rows?: TableServerMappedDailyExecutions[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type DailyExecutionRow = TableServerMappedDailyExecutions;

/*********************** TableServerJoinedTrades *************************/

export type TableServerJoinedTrades = {
  id: string;
  price: number;
  locate: string;
  side: string;
  quantity: number;
  capacity: string;
  tradeCounterParty: string;
  tradeContraAccount: string;
  tradeContraAccountName: string;
  lastMarket: string;
  lastTradeTime: Date;
  createdTime: Date;
  updatedTime: Date;
  owner: string;
  traderByName: string;
  rawLiquidityIndicator: string;
  normalizedLiquidityIndicator: string;
  status: string;
  tradingOrderId: string;
  settleType: string;
  settleDate: Date;
};


export const TsJoinedTradessWithFilterDocument = gql`
  subscription OnTsJoinedTradessWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsJoinedTradessWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsJoinedTradesQueryResult {
        rows {
          id
          price
          locate
          side
          quantity
          capacity
          tradeCounterParty
          tradeContraAccount
          tradeContraAccountName
          lastMarket
          lastTradeTime
          createdTime
          updatedTime
          owner
          traderByName
          rawLiquidityIndicator
          normalizedLiquidityIndicator
          status
          tradingOrderId
          settleType
          settleDate
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsJoinedTradesPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsJoinedTradessWithFilterSubscription = {
  tsJoinedTradessWithFilter: {
    rows?: TableServerJoinedTrades[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type JoinedTradesRow = TableServerJoinedTrades;

/*********************** TableServerAggregatedVis *************************/

export type TableServerAggregatedVis = {
  viewer_id: string;
  reasons: string[];
};

/*********************** TableServerAllocationMapIOsByTOs *************************/

export type TableServerAllocationMapIOsByTOs = {
  investorOrderIds: string[];
};

/*********************** TableServerAllocationMapTOsByIOs *************************/

export type TableServerAllocationMapTOsByIOs = {
  tradingOrderIds: string[];
};

/*********************** TableServerAggregatedTradesByPrice *************************/

export type TableServerAggregatedTradesByPrice = {
  id: string;
  price: number;
  quantity: number;
  tradingOrderId: string;
};


export const TsTradesGroupedByPricesWithFilterDocument = gql`
  subscription OnTsTradesGroupedByPricesWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsTradesGroupedByPricesWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsTradesGroupedByPriceQueryResult {
        rows {
          id
          price
          quantity
          tradingOrderId
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsTradesGroupedByPricePatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }`;


export type TsTradesGroupedByPricesWithFilterSubscription = {
  tsTradesGroupedByPricesWithFilter: {
    rows?: TableServerAggregatedTradesByPrice[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};


export type TradesGroupedByPriceRow = TableServerAggregatedTradesByPrice;
