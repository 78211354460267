import { InvestorAccountType, InvestorOrderStatus } from '@oms/generated/frontend';
import type { InvestorOrderRow } from '@oms/generated/frontend';

export const internalizationCommonFilters = (order: InvestorOrderRow, userId: string | null | undefined) => {
  const accountTypeFilter = order.investorAccountType === InvestorAccountType.Client;
  const openQuantityFilter = order.openQuantity && order.openQuantity > 0;
  const statusFilter = order.status === InvestorOrderStatus.Active;
  const ownerFilter = userId ? order.ownerID === userId : false;

  return Boolean(accountTypeFilter && openQuantityFilter && statusFilter && ownerFilter);
};
