import { useState, useCallback } from 'react';
import {
  Flex,
  Switch,
  TabContent,
  Tabs,
  TabsList,
  TabTrigger,
  Text
} from '@oms/shared-frontend/ui-design-system';
import { InvestorOrderSummary } from './investor-order-view.order-summary.widget';
import { withUpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import type { UpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import { paneStyle as sx } from './investor-order-view.style';

enum ViewMode {
  TODAY = 'Today',
  TOTAL = 'Total'
}

export const InvestorOrderViewTabbedSummariesWidget = ({ data: investorOrder }: UpdatedInvestorOrder) => {
  const [isToday, setToday] = useState<boolean>(true);

  const handleToggle = useCallback(() => {
    setToday(!isToday);
  }, [isToday]);

  return (
    <Tabs defaultValue="order-summary" sx={{ ...sx, height: 'full' }}>
      <TabsList
        style={{
          padding: 0,
          margin: '0 15px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Flex sx={{ gap: 4 }}>
          <TabTrigger value="order-summary">Order Summary</TabTrigger>
          <TabTrigger value="charges">Charges</TabTrigger>
        </Flex>
        <Flex sx={{ gap: 4 }}>
          <Text sx={{ color: isToday ? 'text.primary' : 'text.semiMinor' }}>{ViewMode.TODAY}</Text>
          <Switch onChange={handleToggle} isChecked={!isToday} size="md" />
          <Text sx={{ color: isToday ? 'text.semiMinor' : 'text.primary' }}>{ViewMode.TOTAL}</Text>
        </Flex>
      </TabsList>
      <TabContent value="order-summary">
        <InvestorOrderSummary investorOrder={investorOrder} isToday={isToday} />
      </TabContent>
      <TabContent value="charges">
        <div>charges to be migrated</div>
        {/* <ChargesSummary investorOrder={investorOrder} isToday={isToday} /> */}
      </TabContent>
    </Tabs>
  );
};

export default withUpdatedInvestorOrder(InvestorOrderViewTabbedSummariesWidget, { sx });
