import { FactsetHighLevelRequestResult, PricesByType } from './factset.types';
import type {
  FactsetKeyFigure52WkRequest,
  FactsetKeyFigure52WkResponse,
  FactsetKeyFigureMnthlyRequest,
  FactsetKeyFigureMnthlyResponse,
  FactsetKeyFigureWklyRequest,
  FactsetKeyFigureWklyResponse,
  FactsetKeyFigureYtdRequest,
  FactsetKeyFigureYtdResponse,
  FactsetMapping,
  FactsetNotationStatusRequest,
  FactsetNotationStatusResponse,
  FactsetPricesByTypeRequest,
  FactsetPricesByTypeResponse,
  FactsetPropertyListRequest,
  FactsetPropertyListResponse,
  FactsetRequestResult,
  Level1FactsetWithFallbackFields,
  MarketDataConfig,
  RawLevel1IntegrationEvent
} from './factset.types';
import type { Observable } from 'rxjs';
import { auditTime, filter, map, merge, share } from 'rxjs';
import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import {
  marketDataErrors,
  factsetMap,
  factsetFinalizeEndpoint,
  logFactsetResponse,
  mapTickDirection
} from './factset.operators';

const SUBSCRIPTION_INTERVAL = 1000;
const POLL_INTERVAL = 86400000;

// STUB: might need later different identifier types
const parseTicker = (ticker: string) => ticker;

// Some of Factset's data comes back as untrimmed strings so this trims those fields.
const trimAllStringValues = (e: any) => {
  Object.entries(e)
    .filter(([_, v]) => isString(v))
    .forEach(([k, v]) => {
      (e as Record<string, any>)[k] = (v as string).trim();
    });
};

const getNumericScale = (val: any): number => val?.toString()?.split('.')?.[1]?.length || 0;

const calculateMidPrice = (e: Level1IntegrationEvent) => {
  const tickSize = e.tickSize || 0;
  const bidPrice = e.bidPrice || 0;
  const askPrice = e.askPrice || 0;
  const scale = getNumericScale(tickSize || bidPrice || askPrice) || -1;
  const avgPrice = (bidPrice + askPrice) / 2;
  const midPrice = scale > 0 ? Number(avgPrice.toFixed(scale)) : avgPrice;

  return midPrice;
};

const transformTickDirection = (e: Level1IntegrationEvent) => {
  e.askTickDirection = mapTickDirection(e.askTickDirection);
  e.bidTickDirection = mapTickDirection(e.bidTickDirection);
  e.tradeTickDirection = mapTickDirection(e.tradeTickDirection);
};

const mapPropertyIds = (propertyIds?: RawLevel1IntegrationEvent['propertyIds']) => {
  return propertyIds && !isEmpty(propertyIds) ? propertyIds.map((item) => item?.id) : [];
};

const transformPropertyIds = (e: Level1IntegrationEvent) => {
  e.propertyIds = mapPropertyIds(e?.propertyIds as RawLevel1IntegrationEvent['propertyIds']);
};

const transformTradeConditions = (e: Level1IntegrationEvent) => {
  const trdCond = e?.trdCond as RawLevel1IntegrationEvent['trdCond']; // TODO: to be renamed tradeCondition
  const matchByPropertyIds = e.propertyIds
    ?.map((propertyId) => trdCond?.find((property) => property?.id === propertyId))
    .filter((p) => !!p)
    .map((p) => p?.shortName);

  e.trdCond = matchByPropertyIds;
  e.lastTradeCondition = matchByPropertyIds?.join(',') || '';
};

const applyEventAndsetValueIfExists =
  (e: Level1FactsetWithFallbackFields) =>
  <T>(field: keyof Level1FactsetWithFallbackFields, value: T | undefined) => {
    if (typeof value !== 'undefined') {
      e[field] = value;
    }
  };

export const applyFallbackValues = (e: Level1FactsetWithFallbackFields) => {
  const setValueIfExists = applyEventAndsetValueIfExists(e);
  setValueIfExists('closeDateTime', e.officialCloseDateTime || e.lastTradeDateTime);
  setValueIfExists('closePrice', e.officialClosePrice || e.lastTradePrice);
  setValueIfExists('closeVolume', e.officialCloseVolume || e.lastTradeSize);
  setValueIfExists('highDayPrice', e.officialHighDayPrice || e.highDayPrice);
  setValueIfExists('lowDayPrice', e.officialLowDayPrice || e.lowDayPrice);
  setValueIfExists('midPrice', e.midPrice || calculateMidPrice(e));
  setValueIfExists('openDateTime', e.officialOpenDateTime || e.openDateTime);
  setValueIfExists('openPrice', e.officialOpenPrice || e.openPrice);
  setValueIfExists(
    'previousCloseDateTime',
    e.previousExCloseDateTime || e.officialCloseDateTime || e.previousCloseDateTime
  );
  setValueIfExists(
    'previousClosePrice',
    e.previousExClosePrice || e.officialClosePrice || e.previousClosePrice
  );
};

const pricesByType = (
  config: MarketDataConfig,
  types: FactsetPricesByTypeRequest['data']['types'],
  mappings: FactsetMapping<Level1FactsetWithFallbackFields>[]
) => {
  const { client, ticker, result, logMissingFields } = config;
  const endpoint = '/prices/getByType';
  const endpoint$ = client.observeEndpoint<FactsetPricesByTypeRequest, FactsetPricesByTypeResponse>({
    method: 'POST',
    endpoint,
    payload: {
      data: {
        identifier: parseTicker(ticker),
        identifierType: 'tickerRegion',
        types,
        quality: 'BST',
        sameQuality: true
      },
      meta: {
        subscription: {
          minimumInterval: SUBSCRIPTION_INTERVAL
        }
      }
    }
  });

  return endpoint$.pipe(
    logFactsetResponse(endpoint, config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetRequestResult<FactsetPricesByTypeResponse>, Level1FactsetWithFallbackFields>({
      logMissingFields,
      result,
      endpoint: '/prices/getByType',
      mappings
    }),
    marketDataErrors(endpoint, config),
    factsetFinalizeEndpoint({ client, endpoint, job: endpoint$ })
  );
};

const notationStatus = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  const endpoint = '/notation/status/get';
  const endpoint$ = client.observeEndpoint<FactsetNotationStatusRequest, FactsetNotationStatusResponse>({
    method: 'GET',
    endpoint,
    payload: {
      identifier: parseTicker(ticker),
      identifierType: 'tickerRegion',
      _subscriptionMinimumInterval: SUBSCRIPTION_INTERVAL
    }
  });

  return endpoint$.pipe(
    logFactsetResponse(endpoint, config.logFactsetResponse),
    factsetMap<FactsetRequestResult<FactsetNotationStatusResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint,
      mappings: [
        ['data.regional.us.caveatEmptor', 'caveatEmptor'],
        ['data.tradeImbalance', 'imbalance'],
        ['data.market.isOpen', 'isMarketOpen'],
        ['data.suspended', 'isSuspended'],
        ['data.lotSize', 'lotSize'],
        ['data.market.phase', 'marketPhase'],
        ['data.tickSize', 'tickSize'],
        ['data.tradingStatus', 'tradingStatus'],
        ['data.shortSaleRestricted', 'shortSellRestricted']
      ]
    }),
    marketDataErrors('/notation/status/get', config),
    factsetFinalizeEndpoint({ endpoint, client, job: endpoint$ })
  );
};

const fiftyTwoWk = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return client
    .pollEndpoint<FactsetKeyFigure52WkRequest, FactsetKeyFigure52WkResponse>({
      interval: POLL_INTERVAL,
      method: 'GET',
      endpoint: '/notation/keyFigures/year/1/get',
      payload: {
        identifier: parseTicker(ticker),
        identifierType: 'tickerRegion'
      }
    })
    .pipe(
      logFactsetResponse('/notation/keyFigures/year/1/get', config.logFactsetResponse),
      factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigure52WkResponse>, Level1IntegrationEvent>({
        logMissingFields,
        result,
        endpoint: '/notation/keyFigures/year/1/get',
        mappings: [
          ['data.high.price', 'high52weekPrice'],
          ['data.high.date', 'high52weekDate'],
          ['data.low.price', 'low52weekPrice'],
          ['data.low.date', 'low52weekDate']
        ]
      }),
      marketDataErrors('/notation/keyFigures/year/1/get', config)
    );
};

const weekly = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return client
    .pollEndpoint<FactsetKeyFigureWklyRequest, FactsetKeyFigureWklyResponse>({
      interval: POLL_INTERVAL,
      method: 'GET',
      endpoint: '/notation/keyFigures/week/1/get',
      payload: {
        identifier: parseTicker(ticker),
        identifierType: 'tickerRegion'
      }
    })
    .pipe(
      logFactsetResponse('/notation/keyFigures/week/1/get', config.logFactsetResponse),
      factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigureWklyResponse>, Level1IntegrationEvent>({
        logMissingFields,
        result,
        endpoint: '/notation/keyFigures/week/1/get',
        mappings: [['data.tradingVolume.average', 'adv5day']]
      }),
      marketDataErrors('/notation/keyFigures/week/1/get', config)
    );
};

const monthly = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return client
    .pollEndpoint<FactsetKeyFigureMnthlyRequest, FactsetKeyFigureMnthlyResponse>({
      interval: POLL_INTERVAL,
      endpoint: '/notation/keyFigures/month/1/get',
      method: 'GET',
      payload: {
        identifier: parseTicker(ticker),
        identifierType: 'tickerRegion'
      }
    })
    .pipe(
      logFactsetResponse('/notation/keyFigures/month/1/get', config.logFactsetResponse),
      factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigureMnthlyResponse>, Level1IntegrationEvent>({
        logMissingFields,
        result,
        endpoint: '/notation/keyFigures/month/1/get',
        mappings: [['data.tradingVolume.average', 'adv30day']]
      }),
      marketDataErrors('/notation/keyFigures/month/1/get', config)
    );
};

const propertyList = (config: MarketDataConfig) => {
  const endpoint = '/prices/property/list';
  const { client, result, logMissingFields } = config;

  return client
    .pollEndpoint<FactsetPropertyListRequest, FactsetPropertyListResponse>({
      method: 'POST',
      endpoint,
      payload: {
        meta: { attributes: ['shortName', 'id'] }
      },
      interval: POLL_INTERVAL
    })
    .pipe(
      logFactsetResponse(endpoint, config.logFactsetResponse),
      factsetMap<FactsetRequestResult<FactsetPropertyListResponse>, Level1IntegrationEvent>({
        logMissingFields,
        result,
        endpoint,
        mappings: [['data', 'trdCond']]
      }),
      marketDataErrors(endpoint, config)
    );
};

const yearToDate = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return client
    .pollEndpoint<FactsetKeyFigureYtdRequest, FactsetKeyFigureYtdResponse>({
      interval: POLL_INTERVAL,
      method: 'GET',
      endpoint: '/notation/keyFigures/yearToDate/get',
      payload: {
        identifier: parseTicker(ticker),
        identifierType: 'tickerRegion'
      }
    })
    .pipe(
      logFactsetResponse('/notation/keyFigures/yearToDate/get', config.logFactsetResponse),
      factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigureYtdResponse>, Level1IntegrationEvent>({
        logMissingFields,
        result,
        endpoint: '/notation/keyFigures/yearToDate/get',
        mappings: [
          ['data.high.price', 'highYtdPrice'],
          ['data.high.date', 'highYtdDate'],
          ['data.low.price', 'lowYtdPrice'],
          ['data.low.date', 'lowYtdDate']
        ]
      }),
      marketDataErrors('/notation/keyFigures/yearToDate/get', config)
    );
};

/**
 * Abstracts calls required to Factset for L1 market data.
 * @param config Configuration required to get factset data
 * @returns L1 market events for the given ticker.
 */
export const level1 = (
  config: MarketDataConfig<Level1IntegrationEvent>
): Observable<Level1IntegrationEvent> => {
  config.result = config.result || {};
  return merge(
    // 1st call to /prices/getByType
    pricesByType(
      config,
      [
        // See PricesByTypes01.java
        PricesByType.TOTAL, // index:0 / type:6
        PricesByType.AUCTION, // index:1 / type:7
        PricesByType.OFFICIAL_OPEN, // index:2 / type:8
        PricesByType.OFFICIAL_HIGH, // index:3 / type:10
        PricesByType.OFFICIAL_LOW, // index:4 / type:11
        PricesByType.POST_TRADING, // index:5 / type:45
        PricesByType.PRE_TRADING, // index:6 / type:46
        PricesByType.VWAP, // index:7 / type:48
        PricesByType.TRADE // index:8 / type:208
      ],
      [
        // TOTAL / index:0 / type:6
        ['data.prices[0].accumulated.volume', 'cumulativeVolume'],
        // AUCTION / index:1 / type:7
        ['data.prices[1].latest.price', 'latestAuctionPrice'],
        ['data.prices[1].first.price', 'openingAuctionPrice'],
        ['data.prices[1].latest.volume', 'latestAuctionVolume'],
        ['data.prices[1].latest.time', 'latestAuctionDateTime'],
        ['data.prices[1].first.time', 'openingAuctionDateTime'],
        // OFFICIAL_OPEN / index:2 / type:8
        ['data.prices[2].latest.time', 'officialOpenDateTime'],
        ['data.prices[2].latest.price', 'officialOpenPrice'],
        // OFFICIAL_HIGH / index:3 / type:10
        ['data.prices[3].latest.price', 'officialHighDayPrice'],
        // OFFICIAL_LOW / index:4 / type:11
        ['data.prices[4].latest.price', 'officialLowDayPrice'],
        // POST_TRADING / index:5 / type:45
        ['data.prices[5].latest.price', 'postTradingPrice'],
        ['data.prices[5].latest.time', 'postTradingDateTime'],
        // PRE_TRADING / index:6 / type:46
        ['data.prices[6].latest.price', 'preTradingPrice'],
        ['data.prices[6].latest.time', 'preTradingDateTime'],
        // VWAP / index:7 / type:48
        ['data.prices[7].latest.price', 'vwap'],
        // TRADE / index:8 / type:208
        ['data.prices[8].latest.tickDirection', 'tradeTickDirection'],
        ['data.prices[8].latest.time', 'lastTradeDateTime'],
        ['data.prices[8].latest.price', 'lastTradePrice'],
        ['data.prices[8].latest.volume', 'lastTradeSize'],
        ['data.prices[8].latest.performance.intraday.absolute', 'priceChange'],
        ['data.prices[8].latest.performance.intraday.relative', 'priceChangePercent'],
        ['data.prices[8].high.price', 'highDayPrice'], // also available: high.time
        ['data.prices[8].low.price', 'lowDayPrice'], // also available: low.time
        ['data.prices[8].previousClose.time', 'previousCloseDateTime'],
        ['data.prices[8].previousClose.price', 'previousClosePrice'],
        ['data.prices[8].first.price', 'openPrice'],
        ['data.prices[8].first.time', 'openDateTime'],
        ['data.prices[8].first.price', 'openPrice'],
        ['data.prices[8].valueUnit.id', 'pricingCurrency'],
        ['data.prices[8].latest.properties', 'propertyIds'], // => is transformed to trade condition
        ['data.prices[8].latest.price', 'limitUpPrice'], // TODO: Confirm latest price == limit up proce
        ['data.prices[8].latest.quoteCondition', 'lastTradeExchange'],
        // OTHER
        ['data.trading.shortSaleRestricted', 'shortSellRestricted'] // 'shortSaleRestricted'
      ]
    ),
    // 2nd call to /prices/getByType
    pricesByType(
      config,
      [
        // See PricesByTypes02.java
        PricesByType.ASK, // index:0 / type:2
        PricesByType.BID, // index:1 / type:4
        PricesByType.EX_CLOSE, // index:2 / type:234
        PricesByType.LOWER_DYNAMIC_THRESHOLD, // index:3 / type:243
        PricesByType.MID, //index:4 / type: 128
        PricesByType.OFFICIAL_CLOSE, // index:5 / type:9
        PricesByType.OFFICIAL_CLOSING_ASK, // index:6 / type:194
        PricesByType.OFFICIAL_CLOSING_BID, // index:7 / type:193
        PricesByType.UPPER_DYNAMIC_THRESHOLD // index:8 / type:241
      ],
      [
        // ASK / index:0 / type:2
        ['data.prices[0].latest.price', 'askPrice'],
        ['data.prices[0].latest.quoteCondition', 'askExchange'],
        ['data.prices[0].latest.tickDeleted', 'askDeleted'],
        ['data.prices[0].latest.tickDirection', 'askTickDirection'],
        ['data.prices[0].latest.time', 'askPriceDateTime'],
        ['data.prices[0].latest.volume', 'askSize'],
        // BID / index:1 / type:4
        ['data.prices[1].latest.price', 'bidPrice'],
        ['data.prices[1].latest.quoteCondition', 'bidExchange'],
        ['data.prices[1].latest.tickDeleted', 'bidDeleted'],
        ['data.prices[1].latest.tickDirection', 'bidTickDirection'],
        ['data.prices[1].latest.volume', 'bidSize'],
        // EX_CLOSE / index:2 / type:234
        ['data.prices[2].latest.price', 'previousExClosePrice'],
        ['data.prices[2].latest.time', 'previousExCloseDateTime'],
        // LOWER_DYNAMIC_THRESHOLD / index:3 / type:243
        ['data.prices[3].latest.price', 'limitDownPrice'],
        // MID / index:4 / type:128
        ['data.prices[4].latest.price', 'midPrice'],
        // OFFICIAL_CLOSE / index:5 / type:9
        ['data.prices[5].latest.volume', 'officialCloseVolume'],
        ['data.prices[5].latest.price', 'officialClosePrice'],
        ['data.prices[5].latest.time', 'officialCloseDateTime'],
        // OFFICIAL_CLOSING_ASK / index:6 / type:194
        ['data.prices[6].latest.price', 'closingAskQuotePrice'],
        ['data.prices[6].latest.volume', 'closingAskVolume'],
        // OFFICIAL_CLOSING_BID / index:7 / type:193
        ['data.prices[7].latest.time', 'closeDateTime'], // TODO: not on backend
        ['data.prices[7].latest.price', 'closingBidQuotePrice'],
        ['data.prices[7].latest.volume', 'closingBidVolume'],
        // UPPER_DYNAMIC_THRESHOLD / index:8 / type:242
        ['data.prices[8].latest.price', 'limitUpPrice'] // TODO: Confirm
      ]
    ),
    notationStatus(config),
    fiftyTwoWk(config),
    weekly(config),
    monthly(config),
    yearToDate(config),
    propertyList(config)
  ).pipe(
    filter((e) => !isEmpty(e)),
    map((e) => {
      trimAllStringValues(e);
      // we need to prevent issues around shared object usage and calculating values stacking on top of each other.
      // i.e. priceChangePercent will store and multiply by 100 each time a new event occurs which will infinitely increase the number.
      const result = structuredClone<Level1IntegrationEvent>(e);

      result.priceChangePercent = (Number(result.priceChangePercent) || 0) * 100;

      applyFallbackValues(result as Level1FactsetWithFallbackFields);
      transformTickDirection(result);
      transformPropertyIds(result);
      transformTradeConditions(result);

      return result;
    }),
    share(),
    auditTime(config.auditTime || 250)
  );
  // NOTE: Useful for testing high freq data and making sure mapping works properly without factset working correctly
  /*return interval(10).pipe(
    mergeMap(() =>
      of<Level1IntegrationEvent>({
        vwap: 461.3246920949192,
        isMarketOpen: false,
        isSuspended: false,
        marketPhase: 'CLOSE',
        tradingStatus: '',
        shortSellRestricted: false,
        high52weekPrice: 468.59,
        high52weekDate: '2024-11-04T00:00:00.000000Z',
        low52weekPrice: 206.39,
        low52weekDate: '2023-11-07T00:00:00.000000Z',
        adv5day: 105782.2,
        adv30day: 86437.57142857143,
        highYtdPrice: 468.59,
        highYtdDate: '2024-11-04T00:00:00.000000Z',
        lowYtdPrice: 209.67,
        lowYtdDate: '2024-01-03T00:00:00.000000Z',
        askPrice: 461.74 * Math.random(),
        askSize: 800,
        askExchange: '',
        askDeleted: true,
        askPriceDateTime: '2024-11-04T20:59:59.715789Z',
        latestAuctionPrice: 461.5,
        openingAuctionPrice: 450.7,
        latestAuctionVolume: 34843,
        latestAuctionDateTime: '2024-11-04T21:00:02.468667Z',
        openingAuctionDateTime: '2024-11-04T14:30:00.868797Z',
        bidPrice: 461.25 * Math.random(),
        bidSize: 700,
        bidExchange: '',
        bidDeleted: true,
        closingAskQuotePrice: 461.74,
        closingAskVolume: 8,
        closeDateTime: '2024-11-04T21:00:02.475070Z',
        closingBidQuotePrice: 461.5,
        closingBidVolume: 7,
        lastTradeDateTime: '2024-11-04T21:00:02.475070Z',
        lastTradePrice: 461.5,
        priceChange: 8.29000000000002,
        priceChangePercent: 1.8291741135456014,
        highDayPrice: 468.59,
        previousCloseDateTime: '2024-11-01T20:00:02.551585Z',
        previousClosePrice: 453.21,
        openDateTime: '2024-11-04T14:30:00.868797Z',
        cumulativeVolume: 83327,
        midPrice: 461.5,
        askTickDirection: Math.round(Math.random()) + 1,
        bidTickDirection: Math.round(Math.random()) + 1,
        tradeTickDirection: Math.round(Math.random()) + 1,
        propertyIds: [{ id: 1 }],
        trdCond: [{ id: 1, shortName: 'Wes' }]
      })
    ),
    filter((e) => !isEmpty(e)),
    map((e) => {
      trimAllStringValues(e);
      // we need to prevent issues around shared object usage and calculating values stacking on top of each other.
      // i.e. priceChangePercent will store and multiply by 100 each time a new event occurs which will infinitely increase the number.
      const result = structuredClone<Level1IntegrationEvent>(e);

      result.priceChangePercent = (Number(result.priceChangePercent) || 0) * 100;

      calculateMidPrice(result);
      transformTickDirection(result);
      transformPropertyIds(result);
      transformTradeConditions(result);

      return result;
    }),
    share(),
    auditTime(config.auditTime || 250)
  );*/
};
