import { z } from 'zod';
import { Z_INVESTOR_ORDER } from './trading.zod.schemas';
import { PriceType, QuantityType } from '@oms/generated/frontend';

/** @deprecated */
const zQuantityType: z.ZodType<QuantityType> = z.enum([
  QuantityType.Blank,
  QuantityType.ExplicitValue,
  QuantityType.OrderSizes
]);

/** @deprecated */
const zPriceType: z.ZodType<PriceType> = z.enum([
  PriceType.AskInside,
  PriceType.BidInside,
  PriceType.Blank,
  PriceType.Market,
  PriceType.Selection
]);

/** @deprecated */
const zStrategyControl = z.object({
  id: z.string().nullable().optional(),
  value: z.string().nullable().optional()
});

/** @deprecated */
const zStrategyParameter = z.object({
  label: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  uiRep: z.string().nullable().optional(),
  value: z.string().nullable().optional()
});

/** @deprecated */
const zStrategy = z.object({
  controls: z.array(zStrategyControl.nullable()).nullable().optional(),
  name: z.string().nullable().optional(),
  parameters: z.array(zStrategyParameter.nullable()).nullable().optional()
});

/** @deprecated */
export const Z_MONTAGE_SETTINGS = {
  QUANTITY_TYPE: zQuantityType,
  PRICE_TYPE: zPriceType,
  STRATEGY_TYPE: zStrategy
} as const;

/** @deprecated */
const zOrderSizeRow = z.object({
  id: z.string(),
  priceFrom: z.number(),
  priceTo: z.number(),
  orderSize: z.number()
});

/** @deprecated */
const zOrderSizeProfile = z.object({
  id: z.string(),
  description: z.string(),
  rows: z.array(zOrderSizeRow).nullish()
});

/** @deprecated */
const zUserPreferences = z
  .object({
    // bid
    bidInitiateOrder: z.boolean(),
    bidSideType: Z_INVESTOR_ORDER.SIDE_TYPE,
    bidQuantityType: Z_MONTAGE_SETTINGS.QUANTITY_TYPE,
    bidQuantityValue: z.union([z.number(), z.string()]),
    bidPriceType: Z_MONTAGE_SETTINGS.PRICE_TYPE,
    bidTimeInForceType: z.object({ id: Z_INVESTOR_ORDER.TIME_IN_FORCE, subValue: z.string() }),
    bidTimeInForceValue: z.number(),
    bidDisplaySize: z.union([z.number(), z.string()]),
    bidDestinationId: z.object({
      id: z.string(),
      label: z.string().nullable(),
      value: z.unknown()
    }),
    bidStrategy: z.object({ id: z.string(), value: z.unknown() }),
    bidOrderSize: z.object({
      id: z.string(),
      label: z.string().nullable(),
      value: z.string()
    }),
    // ask
    askInitiateOrder: z.boolean(),
    askSideType: Z_INVESTOR_ORDER.SIDE_TYPE,
    askQuantityType: Z_MONTAGE_SETTINGS.QUANTITY_TYPE,
    askQuantityValue: z.union([z.number(), z.string()]),
    askPriceType: Z_MONTAGE_SETTINGS.PRICE_TYPE,
    askTimeInForceType: z.object({ id: Z_INVESTOR_ORDER.TIME_IN_FORCE, subValue: z.string() }),
    askTimeInForceValue: z.number(),
    askDisplaySize: z.union([z.number(), z.string()]),
    askDestinationId: z.object({
      id: z.string(),
      label: z.string().nullable(),
      value: z.unknown()
    }),
    askStrategy: z.object({ id: z.string(), value: z.unknown() }),
    askOrderSize: z.object({
      id: z.string(),
      label: z.string().nullable(),
      value: z.string()
    }),
    // common
    orderMappings: z.any(),
    orderViewOptions: z.any(),
    hideOddLots: z.boolean(),
    displayQuotesInShares: z.boolean(),
    sendAttributable: z.boolean()
  })
  .deepPartial()
  .extend({
    orderSettings: z.array(zOrderSizeProfile).optional()
  });

/** @deprecated */
export const Z_USER_PREFERENCES = {
  USER_PREFERENCES: zUserPreferences
} as const;
