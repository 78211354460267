import { openRejectWithReason } from '@app/generated/sdk';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import type { ActionContext, ActionComponentConfig, ActionDefFactory } from '@oms/frontend-vgrid';
import type { TradeRepairRequestFragment } from '@oms/generated/frontend';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';

function isVisible(rowData: TradeRepairRequestFragment[]) {
  return rowData.length > 0;
}

const rejectRepairQueueTradesOnChange = async (
  ctx: ActionContext<TradeRepairRequestFragment, ActionComponentConfig<TradeRepairRequestFragment>>
) => {
  const { appContainer, lifecycle, data, workspace } = ctx;

  const isEnabled = isVisible(data);

  ctx.notify({ isDisabled: !isEnabled });

  if (lifecycle === 'change' && isEnabled) {
    const [_, api] = await openRejectWithReason(workspace, workspace.getLeaderProcessId());
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        const tradeRepairRequestIds = data.map((row) => row.id || '');

        const tradeRepairRequestService = appContainer.resolve(TradeRepairRequestService);

        const response = await tradeRepairRequestService.rejectWithReason(
          tradeRepairRequestIds,
          event.payload as string
        );

        if (!response.isSuccess()) {
          console.error(response.errors);
        }
        break;
      }
    }
  }
};

export const rejectRepairQueueTradesAction: ActionDefFactory<TradeRepairRequestFragment> = (builder) =>
  builder
    .name('reject_repair_queue_trades')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('reject_repair_queue_trades_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Reject'
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.RepairQueueManage]);
    })
    .customMenu((m) => m.name('Reject').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<TradeRepairRequestFragment>>(rejectRepairQueueTradesOnChange);
