import type {
  Priority,
  ResourceType,
  VisibilityReason,
  FeedbackWrapper,
  InvestorOrder
} from '@oms/generated/frontend';
import type { Prettify } from '@oms/shared/util-types';
import type { IOVisKey } from '@app/widgets/trading/investor-order-monitor/grid-services/investor-order-monitor.visibility.filters';

// temp type until we have it in generated
export type NotificationResourceTemplatesName =
  | 'IO Cancel Request'
  | 'IO Modify Request'
  | 'IO New'
  | 'IO Failed'
  | 'IO Failed - Modify'
  | 'Capital Threshold Warning'
  | 'Failed Trade'
  | 'Failed Trade - Modify'
  | 'Unmatched Trade Report'
  | 'Trade Report Failed (rejected)'
  | 'TO Create Rejected'
  | 'TO Modify Rejected'
  | 'TO Cancel Rejected'
  | 'TO Unsolicited Cancelled - Partial Fills'
  | 'Trade Request (OTC)';

// TODO: align this type with BE
type PrimaryAlertType = IOVisKey;

// TODO: We should use a common enum (shared between FE/BE). This is temporary.
export enum PrimaryAlertTypeTemp {
  MyAlert = 'MY_ALERT',
  TeamAlert = 'TEAM_ALERT',
  AllAlert = 'ALL_ALERT'
}

export type NotificationUserDelivery = {
  notificationCommonId: string;
  createdAt: string;
  addressedUserId: string;
  primaryAlertType: PrimaryAlertType;
  isSound: boolean;
  isPopup: boolean;
  isShown: boolean;
};

export type Notification = Prettify<
  {
    id: string;
    name: NotificationResourceTemplatesName;
    createdAt: string; // Notification metadata (Row 2)
    priority: Priority;
    addresseeType: string; // AddresseeType;
    addressedUserId: string;
    visibilityReason: VisibilityReason;
    resourceFields: Record<string, string>; // JSON fields to build notification message (Row 1)
    feedbackMetadata: FeedbackWrapper[]; // Feedback Wrapper. Notification metadeata (Row 2)
    targetObjectType: ResourceType; // type of the object type (IO, TO, Repair, etc.)
    targetObjectId: string; // ID of the object
    formatting?: string;
    sourceEventId?: string;
    sourceEventName?: string; // TODO: chack if it is needed
    sourceEventTimestamp?: string; // TODO: chack if it is needed
    sourceEventPayload?: string; // may be usefull to get target object data (IO fragment, TO fragment, etc.)
    // Depending on the notification type (name), there can be different objects included in the record.
    investorOrder?: InvestorOrder; // 'IO_NEW' notifications can have an investorOrder object
  } & Partial<NotificationUserDelivery>
>;
