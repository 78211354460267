import { t } from '@oms/codegen/translations';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import type { InvestorOrderRow } from '@oms/generated/frontend';

export const isGenerateOrderSummaryVisible = (rowData?: InvestorOrderRow): boolean =>
  rowData !== undefined &&
  rowData?.executedQuantity !== null &&
  rowData?.executedQuantity !== undefined &&
  rowData?.executedQuantity > 0;

function isVisible(rowData: InvestorOrderRow[]): boolean {
  return rowData && rowData.length === 1 && rowData[0] && isGenerateOrderSummaryVisible(rowData[0]);
}

const getGenerateOrderSummaryLabel = () => t('app.orders.generateOrderSummary.generateOrderSummary');

export const investorOrderGenerateOrderSummaryOnChange = async (
  ctx: ActionContext<InvestorOrderRow, ActionComponentConfig<InvestorOrderRow>>
) => {
  const { lifecycle, data, workspace, appContainer } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }

    const orderService = appContainer.resolve(InvestorOrdersService);

    try {
      const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
        title: t('app.orders.generateOrderSummary.generateOrderSummary'),
        componentProps: {
          autoClose: true,
          message: t('app.orders.generateOrderSummary.generateOrderSummaryMessage'),
          confirmButtonText: t('app.common.yes'),
          cancelButtonText: t('app.common.no')
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          const result = await orderService.generateInvestorOrderSummary(selectedRow.id);
          ctx.notify({ isLoading: false });

          if (result.isFailure()) {
            const msgs = result?.errors.map((e) => e.message).join(', ');
            openMessageDialog(`Error: ${msgs}`, workspace).catch(console.error);
            throw new Error(msgs);
          }
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const investorOrderGenerateOrderSummaryAction: ActionDefFactory<InvestorOrderRow> = (builder) =>
  builder
    .name('investor_order_generate_order_summary')
    .customMenu((m) => m.name(getGenerateOrderSummaryLabel()).visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<InvestorOrderRow>>(investorOrderGenerateOrderSummaryOnChange);
