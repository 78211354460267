import { migrateSubSchema } from '@oms/frontend-schemas';
import type {
  CollectionWithSchemaPayload,
  CollectionMigrationStrategy,
  SubSchemaItem
} from '@oms/frontend-schemas';
import { VERSIONED_SCHEMAS as bumpPriceVersionedSchemas } from '@app/widgets/trading/montage/commands/bump-price/schema';
import { VERSIONED_SCHEMAS as createUnboundTradingOrderVersionedSchemas } from '@app/widgets/trading/route-order/commands/create-unbound-trading-order/schema';
import { VERSIONED_SCHEMAS as snapshotVersionedSchemas } from '@app/app-config/schemas/snapshots';
import { VERSIONED_SCHEMAS as flexLayoutVersionedSchemas } from '@app/app-config/schemas/flex-layout';
import { SnapshotDocType } from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import { migrateSnapshotSubSchema } from './sub-schema.snapshots';
import { SNAPSHOT_SUB_SCHEMA_REGISTRY } from '@app/generated/snapshot.schema-registry';
import { CUSTOM_SUB_SCHEMA_CLI, CUSTOM_SUB_SCHEMA_REGISTRY_CLI } from './sub-schema.custom.registry';
import { SNAPSHOT_APP_SUB_SCHEMA } from '@app/generated/snapshot.schema-registry.contracts';

export const APP_SUB_SCHEMA = {
  // Custom sub-schemas
  ...CUSTOM_SUB_SCHEMA_CLI,
  // Code-gen snapshot-based sub-schemas
  ...SNAPSHOT_APP_SUB_SCHEMA
} as const;

export type AppSubSchemaType = (typeof APP_SUB_SCHEMA)[keyof typeof APP_SUB_SCHEMA];

export type AppSubSchemaRegistry = Partial<Record<AppSubSchemaType, SubSchemaItem>>;

export const APP_SUB_SCHEMA_REGISTRY: AppSubSchemaRegistry = {
  [APP_SUB_SCHEMA.BUMP_PRICE]: {
    ...CUSTOM_SUB_SCHEMA_REGISTRY_CLI.BUMP_PRICE!,
    versionedSchemas: bumpPriceVersionedSchemas
  },
  [APP_SUB_SCHEMA.CREATE_UNBOUND_TO]: {
    ...CUSTOM_SUB_SCHEMA_REGISTRY_CLI.CREATE_UNBOUND_TO!,
    versionedSchemas: createUnboundTradingOrderVersionedSchemas
  },
  [APP_SUB_SCHEMA.SNAPSHOT]: {
    ...CUSTOM_SUB_SCHEMA_REGISTRY_CLI.SNAPSHOT!,
    versionedSchemas: snapshotVersionedSchemas
  },
  [APP_SUB_SCHEMA.FLEX_LAYOUT]: {
    ...CUSTOM_SUB_SCHEMA_REGISTRY_CLI.FLEX_LAYOUT!,
    versionedSchemas: flexLayoutVersionedSchemas
  },
  // Code-gen snapshot-based sub-schemas
  ...SNAPSHOT_SUB_SCHEMA_REGISTRY
};

export function migrateSubSchemaFromRegistry<
  S extends CollectionMigrationStrategy,
  T extends CollectionWithSchemaPayload<any>
>(strategy: S, doc: T) {
  switch (true) {
    case strategy === 'payload-based' && 'payload' in doc:
      return migrateSubSchema(doc, APP_SUB_SCHEMA_REGISTRY);
    case strategy === 'snapshot' && 'payload' in doc:
      // TODO: improve this type
      return migrateSnapshotSubSchema(doc as unknown as SnapshotDocType, APP_SUB_SCHEMA_REGISTRY);
    default: {
      throw new Error('Invalid strategy or document type');
    }
  }
}
