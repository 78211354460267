import { InvestorOrderStatus } from '@oms/generated/frontend';
import type { InvestorOrderRow } from '@oms/generated/frontend';
import { CellBadgeClassEnum } from '@oms/frontend-vgrid';

import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';

export const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

export const newOrdersGridColumns: ColDefMap<InvestorOrderRow> = {
  id: {
    extends: 'orders.id',
    floatingFilter: false,
    hide: true,
    filterParams: { filterOptions: ['equals'] }
  },
  investorAccountName: { extends: 'orders.investorAccount' },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  instrument: { extends: 'orders.instrumentId', hide: true },
  instrumentLongName: { extends: 'orders.instrumentLongName', hide: true },
  instrumentCusip: { extends: 'orders.instrumentCusip', hide: true },
  instrumentExchCode: { extends: 'orders.instrumentExchCode', hide: true },
  side: { extends: 'orders.side' },
  limitPrice: { extends: 'orders.limitPrice' },
  totalQuantity: { extends: 'orders.quantity' },
  transmittedTimestamp: { extends: 'generic.timestamp' },
  orderType: { extends: 'orders.orderType', hide: true },
  status: {
    extends: 'orders.status',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(InvestorOrderStatus),
      valueFormatter: 'investorOrderStatus'
    },
    valueFormatter: 'investorOrderStatus',
    cellClass: CellBadgeClassEnum.Capital,
    hide: true
  },
  clientOrderId: { extends: 'orders.id', hide: true },
  orderEntryType: { extends: 'orders.orderEntryType', hide: true },
  ownerName: {
    extends: 'orders.owner'
  },
  ownerID: {
    extends: 'orders.ownerId',
    hide: true
  },
  orderVisibilityReason: {
    extends: 'orders.visibilityReason',
    hide: true
  },
  timeInForce: { extends: 'orders.timeInForce', hide: true },
  sendingDesk: { extends: 'generic.text', hide: true },
  underlyingAccount: { extends: 'generic.text', hide: true },
  createdTimestamp: { extends: 'generic.timestamp', hide: true },
  validatedTimestamp: { extends: 'generic.timestamp', sort: 'desc', hide: true },
  updatedTime: { extends: 'generic.dateTime', hide: true },
  expiryDateTime: { extends: 'generic.dateTime' }
};

export const newOrdersGridColumnLibrary: TableServerColumnLibrary<InvestorOrderRow> = {
  defaultColumn,
  columns: newOrdersGridColumns
};

export const buildNewOrdersGridColumnDefs = () => buildColumnDefs(newOrdersGridColumnLibrary, 'newOrders');
