import { ApolloLink, HttpLink, split, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import type { ErrorLink } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { MessageType, createClient, stringifyMessage } from 'graphql-ws';
import type { Client } from 'graphql-ws';
import type { WildcardMockLink } from 'wildcard-mock-link';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { getEnvVar } from '@app/common/env/env.util';
import { createGraphQlTracingLink } from './apollo-client.tracing';
import { keycloakInstance } from '@app/common/auth/keycloak.client-instance';
import { createLogger } from '@oms/shared/util';
import { container } from 'tsyringe';
import { AuthService } from '../services/system/auth/auth.service';
import { IS_TAURI } from '@app/common/workspace/workspace.constants';
import { relaunch } from '@tauri-apps/api/process';
import { RxApolloClient } from './rx-apollo-client';
import { BroadcastInMemoryCache } from './broadcast-apollo-cache';
import { dialog } from '@tauri-apps/api';

/**
 * Logger
 */
export const apolloLogger = createLogger({ label: 'Apollo' });

/**
 * Should telemetry be enabled
 */
const enableTelemetry = getEnvVar('NX_UI_ENABLE_TELEMETRY') === 'true';

/**
 * Common type for getting the auth token
 */
export type ApolloClientGetAuthToken = () => string;

/**
 * Get the GraphQL server URL
 *
 * @returns string
 */
const getGraphQlServerUrl = (): string => {
  if (getEnvVar('NX_NEST_API_HOST')) {
    return `${getEnvVar('NX_NEST_API_HOST')}${
      getEnvVar('NX_NEST_API_PORT') ? `:${getEnvVar('NX_NEST_API_PORT')}` : ''
    }`;
  } else {
    return window.location.host;
  }
};

// Don't include a port if none specified
const graphqlServerUrl = getGraphQlServerUrl();

/**
 * Timeout HTTP error link
 */
export const timeoutLink = new ApolloLinkTimeout(7500); // 7.5 second timeout

/**
 * Create an Apollo Auth Link that adds the auth token to the request headers
 *
 * @param getAuthToken - Function to get the auth token
 * @returnsApolloLink
 */
const createApolloAuthLink = (getAuthToken?: ApolloClientGetAuthToken) =>
  new ApolloLink((operation, forward) => {
    const token = typeof getAuthToken === 'function' ? getAuthToken() : '';

    if (!token) {
      apolloLogger.warn('No token found, most likely because it has expired. Logging user out.');
      alert('Your session has expired.');
      const authService = container.resolve(AuthService);
      authService.logout();
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }));

    return forward(operation);
  });

/**
 * Abort controller and signal to abort fetch requests
 */
const abortController = new AbortController();
const abortSignal = abortController.signal;

/**
 * Create an Apollo HTTP link that adds the auth token to the request headers
 *
 * @returns HttpLink
 */
const createApolloHttpLink = () =>
  new HttpLink({
    uri: `${getEnvVar('NX_NEST_API_SCHEME')}://${graphqlServerUrl}/graphql`,
    fetch: (input, init) => {
      // Merge custom headers with any existing headers
      const headers = { ...init?.headers } as HeadersInit;
      return fetch(input, { ...init, headers, signal: abortSignal });
    }
  });

/**
 * Set-up an auth-based Apollo HTTP link with added tracing support
 * @param {ApolloClientGetAuthToken} [getAuthToken] - Optional function to retrieve authentication token
 * @returns {ApolloLink} An ApolloLink instance that handles authentication, tracing, and HTTP requests for GraphQL
 */
export const createApolloAuthHttpTracingLink = (getAuthToken?: ApolloClientGetAuthToken) => {
  const authLink = createApolloAuthLink(getAuthToken);
  const httpLink = createApolloHttpLink();

  const graphQlTracingLink = createGraphQlTracingLink(enableTelemetry);
  return authLink.concat(graphQlTracingLink).concat(httpLink);
};

/**
 * Set-up a WebSocket-based link for Apollo
 * Specifying the browsers native WebSocket implementation to allow it to also be used in workers
 * assume we need to use wss if the API is using https
 */
const webSocketProto = getEnvVar('NX_NEST_API_SCHEME') === 'https' ? 'wss' : 'ws';

/**
 * Cue up a ping message to send in `keepAlive` ms
 *
 * @param activeSocket - socket to send ping message on
 * @param keepAlive - interval in ms between pings
 * @param getAuthToken - function to retrieve auth token
 */
let delayedSendSubscriptionPingTimeout: NodeJS.Timeout;
const delayedSendSubscriptionPing = (
  activeSocket: WebSocket,
  keepAlive: number,
  getAuthToken?: ApolloClientGetAuthToken
) => {
  delayedSendSubscriptionPingTimeout = setTimeout(() => {
    const authToken = typeof getAuthToken === 'function' ? getAuthToken() : '';

    if (!authToken) {
      apolloLogger.warn('No token found, most likely because it has expired. Logging user out.');
      return;
    }

    activeSocket.send(
      stringifyMessage({
        type: MessageType.Ping,
        payload: { authToken }
      })
    );
  }, keepAlive);
};

/**
 * This function was blatantly copied from the graphql-ws npm package
 * https://github.com/enisdenjo/graphql-ws/blob/master/src/client.ts
 *
 * @param closeEvent event automatically provided by apollo when a connection is closed
 * @returns whether the error that caused the event is fatal.
 */
function isFatalInternalCloseCode({ code, reason }: CloseEvent): boolean {
  if (
    [
      1000, // Normal Closure is not an erroneous close code
      1001, // Going Away
      1006, // Abnormal Closure
      1005, // No Status Received
      1012, // Service Restart
      1013 // Bad Gateway/Try Again Later
    ].includes(code)
  ) {
    return false;
  } else {
    apolloLogger.log(`Connection closed with code ${code} and reason ${reason}`);
    // all other internal errors are fatal
    return code >= 1000 && code <= 1999;
  }
}

// biggest integer possible so we can keep re-trying.
const KEEP_RETRYING_FOREVER = Number.MAX_SAFE_INTEGER;

/**
 * retry with linear backoff UP TO 5 seconds, then use static interval
 */
const getRetryIntervalMs = (attempts: number) => {
  if (attempts < 5) {
    return (attempts + 1) * 1_000;
  }

  // max
  return 5_000;
};

/**
 * Create a GraphQL WS client that adds the auth token to the request headers
 *
 * @param getAuthToken - Function to get the auth token
 * @returns Client - GraphQL WS client
 */
let isRestarting = false;
export const createGraphQLAuthWsClient = (
  isLeader: boolean,
  getAuthToken: ApolloClientGetAuthToken,
  keepAlive = 10_000
): Client => {
  let activeSocket: WebSocket;
  return createClient({
    url: `${webSocketProto}://${graphqlServerUrl}/graphql`,
    connectionParams: () => ({ authToken: getAuthToken() }),
    on: {
      error: (wsError) => {
        apolloLogger.log({ wsError });
      },
      opened: (socket) => {
        activeSocket = socket as WebSocket;
      },
      pong: (received: boolean, _payload?: Record<string, unknown>) => {
        // Disable pong if `keepAlive` is 0
        if (received && keepAlive > 0) {
          delayedSendSubscriptionPing(activeSocket, keepAlive, getAuthToken);
        }
      },
      closed: () => {
        if (delayedSendSubscriptionPingTimeout) {
          clearTimeout(delayedSendSubscriptionPingTimeout);
        }
      },
      connecting: () => {},
      connected: () => {
        // Disable ping if `keepAlive` is 0
        if (keepAlive > 0) {
          delayedSendSubscriptionPing(activeSocket, keepAlive, getAuthToken);
        }
      }
    },
    /**
     * Connection must not be lazy, we need to execute connectionParams everytime we try to re-connect,
     * to get the most recent auth token from local storage.
     */
    lazy: false,
    isFatalConnectionProblem: (event: unknown) => {
      if (typeof event === 'object' && event && (event as CloseEvent).type === 'error') {
        // if ws error, keep retrying
        return false;
      } else {
        return isFatalInternalCloseCode(event as CloseEvent);
      }
    },
    retryAttempts: KEEP_RETRYING_FOREVER,
    retryWait: (attempts) => {
      // this is trying practically forever, backoff to a max interval.
      // there isn't a reason to backoff and there isn't a reason to ever stop trying to connect.
      // the front-end should basically make a best effort to maintain a connection indefinitely.
      // we don't lose anything by continuing to re-connect whenever we get disconnected
      const retryAfterMs = getRetryIntervalMs(attempts);

      return new Promise((resolve, reject) => {
        // refresh token if it is expiring before next retry
        keycloakInstance
          .updateToken(attempts + 1)
          .then((refreshed) => {
            apolloLogger.log(
              `Retry ${attempts + 1}. Token refreshed: ${refreshed}. Try again in ${
                retryAfterMs / 1_000
              } second(s).`
            );

            setTimeout(resolve, retryAfterMs);
          })
          .catch((e) => {
            apolloLogger.error('Failed to refresh the token, or the session has expired');

            if (isRestarting || !isLeader) {
              return;
            }

            isRestarting = true;

            const msg =
              'The real-time connection to the server has unexpectedly been closed. Restarting application.';
            if (IS_TAURI) {
              dialog
                .message(msg)
                .then(() => {
                  relaunch().catch(console.error);
                })
                .catch(console.error);
            } else {
              alert(msg);
              const rootContainer = container.resolve(AuthService);
              rootContainer.logout();
              reject(e);
            }
          });
      });
    }
  });
};

/**
 * Create an Apollo WS link that adds the auth token to the request headers
 *
 * @param getAuthToken - Function to get the auth token
 * @param client - GraphQL WS client
 * @returns GraphQLWsLink - Apollo WS link
 */
const createApolloAuthWsLink = (
  isLeader: boolean,
  getAuthToken: ApolloClientGetAuthToken,
  client?: Client
) => {
  return new GraphQLWsLink(client ? client : createGraphQLAuthWsClient(isLeader, getAuthToken));
};

/**
 * Create an Apollo HTTP link that adds the auth token to the request headers
 *
 * @param getAuthToken - Function to get the auth token
 * @returns ApolloLink
 */
const createApolloAuthHttpLink = (getAuthToken?: ApolloClientGetAuthToken) => {
  const authLink = createApolloAuthLink(getAuthToken);
  const httpLink = createApolloHttpLink();
  return authLink.concat(httpLink);
};

/**
 * Create an Apollo split link that adds the auth token to the request headers
 * and splits the link between HTTP and WS
 *
 * @param getAuthToken - Function to get the auth token
 * @param client - GraphQL WS client
 * @returns ApolloLink
 */
const createApolloAuthSplitLink = (
  isLeader: boolean,
  getAuthToken: ApolloClientGetAuthToken,
  client?: Client
) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    createApolloAuthWsLink(isLeader, getAuthToken, client),
    createApolloAuthHttpLink(getAuthToken)
  );

/**
 * Create an Apollo error link that handles errors
 *
 * @param errorHandler - Function to handle errors
 * @returns ApolloLink
 */
const createApolloErrorLink = (errorHandler?: ErrorLink.ErrorHandler) => {
  return onError(errorHandler as ErrorLink.ErrorHandler);
};

/**
 * Apollo client link type
 */
export type ApolloClientLinkType = 'http' | 'auth-http' | 'auth-http-ws' | 'mock';

/**
 * Options for creating an Apollo client
 */
export type CreateApolloClientOptions = {
  errorHandler?: ErrorLink.ErrorHandler;
  isLeader: boolean;
  graphqlWsClient?: Client;
  cache?: BroadcastInMemoryCache;
  getAuthToken?: ApolloClientGetAuthToken;
  mockLink?: WildcardMockLink;
};

/**
 * Create or update an Apollo Client based on the link type
 */
export const createOrUpdateApolloClient = <TLink extends ApolloClientLinkType>(
  linkType: TLink,
  options: CreateApolloClientOptions = {
    isLeader: true
  },
  apolloClient: RxApolloClient | null = null
) => {
  const { errorHandler, graphqlWsClient, isLeader, getAuthToken, mockLink, cache } = options || {};
  let links: ApolloLink[] = [];

  switch (linkType) {
    case 'http':
      links = [createApolloHttpLink()];
      break;
    case 'auth-http':
      links = [createApolloAuthHttpLink(getAuthToken)];
      break;
    case 'auth-http-ws': {
      if (!getAuthToken) throw new Error('getAuthToken is required for auth-http-ws');

      links = [createApolloAuthSplitLink(isLeader, getAuthToken, graphqlWsClient)];
      break;
    }
    case 'mock':
      if (mockLink) {
        links = [mockLink];
      }
      break;
  }

  const combinedLink = errorHandler ? from([createApolloErrorLink(errorHandler), ...links]) : from(links);

  // ------ Update existing apollo client instance ------

  if (apolloClient) {
    apolloClient.setLink(combinedLink);
    return apolloClient;
  }

  // ------ New apollo client instance ------

  const rxCache = cache
    ? cache
    : new BroadcastInMemoryCache({
        typePolicies: {
          Subscription: {
            fields: {}
          }
        }
      });

  return new RxApolloClient({
    cache: rxCache,
    link: combinedLink,
    connectToDevTools: true
  });
};
