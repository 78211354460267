import { OrderType } from '@oms/generated/frontend';
import type { Maybe, TradeInfoFragment, OrderSide } from '@oms/generated/frontend';
import { zOrderTitleDetailsSchema, zTradeDescriptionSchema } from '../../zod/trading.zod.schemas';

type OrderTitleDetails = {
  sideType: Maybe<string>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  price: Maybe<number | string>;
  account?: Maybe<string>;
};

export type OrderDialogInfo = {
  id?: string;
  side: Maybe<string>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  orderType: Maybe<string>;
  price: Maybe<string | number>;
};

export const getOrderDialogTitle = (
  dialogInfo: OrderDialogInfo,
  type: 'trading-order' | 'investor-order'
) => {
  const id = dialogInfo.id;
  const titleDetails: OrderTitleDetails = {
    sideType: dialogInfo?.side,
    symbol: dialogInfo?.symbol,
    quantity: dialogInfo?.quantity,
    price: dialogInfo?.orderType === OrderType.Market ? OrderType.Market : dialogInfo?.price
  };

  let title = '';
  if (type === 'investor-order') {
    title += 'Investor Order - ';
  } else if (type === 'trading-order') {
    title += 'Trading Order - ';
  }

  const result = zOrderTitleDetailsSchema.safeParse(titleDetails);
  if (result.success) {
    const { sideType, quantity, symbol, price } = result.data;
    title += `${sideType} ${quantity} ${symbol} @ ${price}`;
  } else if (id) {
    title += `${id}`;
  } else {
    title += 'Unknown';
  }
  return title;
};

type TradeDescriptionDetails = {
  side: Maybe<OrderSide>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  price: Maybe<number | string>;
  account?: Maybe<string>;
};

export const getTradeDescription = (trade: TradeInfoFragment) => {
  const id = trade.id;
  const titleDetails: TradeDescriptionDetails = {
    side: trade?.side,
    symbol: trade?.instrument?.mappings?.displayCode,
    quantity: trade?.tradeQuantity,
    price: trade?.tradePrice
  };

  let description = '';

  const result = zTradeDescriptionSchema.safeParse(titleDetails);
  if (result.success) {
    const { side, quantity, symbol, price } = result.data;
    description += `${side} ${quantity} ${symbol} @ ${price}`;
  } else if (id) {
    description += `${id}`;
  } else {
    description += 'Unknown';
  }
  return description;
};
