import { catchError, filter, timer, map, Observable, Subscription, switchMap } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { Disposable } from 'tsyringe';
import { v4 as UUID } from 'uuid';
import type { NotificationSignal, NotificationEvent } from './notifications.contracts';
import { NotificationVisiblitySignal } from './notifications.signals';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { allAlertsMockData } from '@app/widgets/trading/notifications/notifications.mock-data';
// import { RepairQueueTab } from '@app/widgets/trading/repair-queue/repair-queue.layout.config';
// import { openNewOrders, openPendingModifications, openRepairQueue } from '@app/generated/sdk';
// import { AppWorkspace } from '@app/app-config/workspace.config';
import { notificationSoundLibrary } from './sound-service';
import { NotificationResourceTemplatesName } from '@app/widgets/trading/notifications/notifications.contracts';
import { testScoped } from '@app/workspace.registry';
import { GetUserDocument, GetUserQuery, GetUserQueryVariables } from '@oms/generated/frontend';

// When mocking, delay for 30 seconds before firing notifications at 5 second intervals.
// This is necessary because otherwise, the mock notifications invoke widgets when the app
// starts and intefere with integration tests. (e.g. in "nx run oms-app:test:integration",
// tests in investor-order-monitor.integration.spec.tsx fail)
const mockData$ = (userSettings: any, signal: NotificationSignal): Observable<NotificationEvent> =>
  timer(30_000, 5_000).pipe(
    map((_i) => {
      const notificationEvent = {
        notification: {
          ...allAlertsMockData[Math.floor(Math.random() * allAlertsMockData.length)],
          id: UUID()
        },
        userPreferences: userSettings,
        visibility: signal,
        error: null
      };

      return notificationEvent;
    })
  );

@testScoped
@singleton()
export class NotificationsBackgroundService implements Disposable {
  private isInitialized = false;
  private subscription: Subscription | undefined = undefined;
  // private appWorkspace: AppWorkspace;

  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(NotificationVisiblitySignal) private visibilitySignal: NotificationVisiblitySignal,
    @inject(AuthService) private authService: AuthService
    // @inject(AppWorkspace) workspace: AppWorkspace
  ) {
    // this.appWorkspace = workspace;
  }

  initialize(): void {
    if (this.isInitialized) {
      return;
    }

    // TODO: Think about retry logic if there are errors?
    // TODO: Think about how to handle errors, and show them in the UI.
    // TODO: Use users service to get user preferences not direct apollo query (See UsersService)

    this.apolloClient
      .rxWatchQuery<GetUserQuery, GetUserQueryVariables>({
        query: GetUserDocument,
        fetchPolicy: 'cache-first',
        variables: {
          id: this.authService.getUserId() ?? ''
        }
      })

      .pipe(
        catchError((err) => {
          console.error(err);
          return [];
        }),
        filter((result) => !!result.data), // TODO: check
        switchMap(({ data: userPref }) =>
          this.visibilitySignal.signal.$.pipe(switchMap((viz) => mockData$(userPref, viz)))
        )
      )
      .subscribe(this.handleNotification.bind(this));

    this.isInitialized = true;
  }

  dispose(): void {
    this.subscription?.unsubscribe();
    this.isInitialized = false;
  }

  private handleNotification(_notificationEvent: NotificationEvent): void {
    /**
     * Handle notification logic:
     * Look up in dictionary of notification types
     * Apply visibility and user preferences filters
     * Pop up?
     * Play sound?
     */
    /**
     * TODO:
     * - Add visibility filter to NewIO grid
     * - Check if widget already open -> ...?
     */
    /**
     * uncomment this block for production

    const { notification } = _notificationEvent;
    switch (notification.name) {
      case 'IO New': {
        openNewOrders(this.appWorkspace.getLeaderProcessId(), {
          componentProps: {
            autoCloseOnEmpty: true
          }
        }).catch(console.error);
        break;
      }
      case 'IO Modify Request': {
        openPendingModifications(this.appWorkspace.getLeaderProcessId(), {
          componentProps: {
            autoCloseOnEmpty: true
          }
        }).catch(console.error);
        break;
      }
      case 'IO Failed':
      case 'IO Failed - Modify':
        openRepairQueue(this.appWorkspace.getLeaderProcessId(), {
          componentProps: {
            tab: RepairQueueTab.INVESTOR_ORDERS,
            autoCloseOnEmpty: true
          }
        }).catch(console.error);
      case 'Failed Trade':
      case 'Failed Trade - Modify':
        openRepairQueue(this.appWorkspace.getLeaderProcessId(), {
          componentProps: {
            tab: RepairQueueTab.TRADES,
            autoCloseOnEmpty: true
          }
        }).catch(console.error);
        break;
    }

    this.playSound(notification.name);

    * end of block
    */
  }

  private playSound(notificationName: NotificationResourceTemplatesName) {
    // TODO: check user preferences (for the given notification type) to see whether we play or suppress the sound.
    try {
      notificationSoundLibrary.get(notificationName)?.play();
    } catch (err) {
      console.error(err);
    }
  }
}
