import { SNAPSHOT_APP_SUB_SCHEMA } from '../generated/snapshot.schema-registry.contracts';
import { COLLECTION_NAME } from '@oms/frontend-schemas';
import type { SubSchemaRegistry } from '@oms/frontend-schemas';

export const CUSTOM_SUB_SCHEMA_CLI = {
  BUMP_PRICE: 'BUMP_PRICE',
  CREATE_UNBOUND_TO: 'CREATE_UNBOUND_TO',
  SNAPSHOT: 'SNAPSHOT',
  FLEX_LAYOUT: 'FLEX_LAYOUT'
} as const;

const appPath = `apps/oms-app/src/app`;

export const CUSTOM_SUB_SCHEMA_REGISTRY_CLI: SubSchemaRegistry = {
  [CUSTOM_SUB_SCHEMA_CLI.BUMP_PRICE]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/montage/commands/bump-price/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.CREATE_UNBOUND_TO]: {
    collection: COLLECTION_NAME.ACTIONS,
    dependencies: [SNAPSHOT_APP_SUB_SCHEMA.ROUTE_ORDER_FORM_VALUES],
    subSchemaFolderPath: `${appPath}/widgets/trading/route-order/commands/create-unbound-trading-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.SNAPSHOT]: {
    collection: COLLECTION_NAME.SNAPSHOTS,
    subSchemaFolderPath: `${appPath}/app-config/schemas/snapshots`,
    type: 'other',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.FLEX_LAYOUT]: {
    subSchemaFolderPath: `${appPath}/app-config/schemas/flex-layout`,
    type: 'other',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  }
};
