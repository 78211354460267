import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('USER_PREFERENCES_V2', {
  version: 0,
  schema: z.object({
    tabId: z
      .enum([
        'montage_settings',
        'market_data_settings',
        'order_settings',
        'notification_settings',
        'my_profile',
        'manage_layouts',
        'appearance',
        'about'
      ])
      .optional()
  })
});
