import { openPrincipleFillForm } from '@app/generated/sdk';
import { TagCode, ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { PROCESS_ID } from '@valstro/workspace';
import { internalizationCommonFilters } from './grid-actions.utils';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { DependencyContainer } from 'tsyringe';
import type { InvestorOrderRow } from '@oms/generated/frontend';

const isActionEnabled = (appContainer: DependencyContainer, data: InvestorOrderRow[]) => {
  if (data.length !== 1) {
    return false;
  }

  const authSerive = appContainer.resolve(AuthService);
  const userId = authSerive.getUserId();
  const tagCodes = data[0].orderTagIds;
  const orderTagsFilter = !(tagCodes.includes(TagCode.Np) && tagCodes.includes(TagCode.Nr));

  return internalizationCommonFilters(data[0], userId) && orderTagsFilter;
};

export const openPrincipalFillAction: ActionDefFactory<InvestorOrderRow> = (builder) =>
  builder
    .name('open_principal_fill')
    .toolbar((t) =>
      t.id('principal_fill').component('action-button').location('HorizontalToolbarRight').props({
        content: 'Principal Fill',
        isDisabled: true
      })
    )
    .customMenu((m) =>
      m.name('Principal Fill').visible(({ rowData, appContainer }) => isActionEnabled(appContainer, rowData))
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.TradeManage]);
    })
    .onChange((e) => {
      const { lifecycle, data, appContainer } = e;

      e.notify({ isDisabled: !isActionEnabled(appContainer, data) });

      const selectedRow = data?.[0];
      const id = selectedRow?.id;

      if (lifecycle === 'change' && id) {
        openPrincipleFillForm(PROCESS_ID.LEADER, {
          form: {
            formId: `principle-fill-${id}`,
            input: {
              ids: data.map(({ id }) => id)
            }
          }
        }).catch((error) => {
          console.error('openPrincipleFillForm error', error);
        });
      }
    });
