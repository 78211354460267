import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import {
  investorOrderMarketData,
  buildInvestorOrderMonitorColumnDefs
} from './investor-order-monitor.columns';
import { InvestorOrderMonitorVisibilityEventHander } from './grid-services/investor-order-monitor.visibility.event-handler';
import { useInvestorOrderMonitorToolbarActions } from './toolbar-actions';
import { acceptInvestorOrderAction } from './grid-actions/accept.investor-order.action';
import { cancelInvestorOrderAction } from './grid-actions/cancel.investor-order.action';
import { investorOrderDoneForDayAction } from './grid-actions/done-for-day.investor-order.action';
import { investorOrderGenerateOrderSummaryAction } from './grid-actions/generate-order-summary.investor-order.action';
import { inlineInvestorOrderActions } from './grid-actions/inline.investor-order.action';
import { rejectInvestorOrderAction } from './grid-actions/reject.investor-order.action';
import { viewInvestorOrderAction } from './grid-actions/view.investor-order.action';
import { openIoEntryInvestorOrderAction } from './grid-actions/open-io-entry.investor-order.action';
import { openPrincipalFillAction } from './grid-actions/open-principal-fill.investor-order.action';
import { openCrossPrincipalFillAction } from './grid-actions/open-cross-principal-fill';
import { openRouteInvestorOrderAction } from './grid-actions/open-route.investor-order.action';
import { openEntryTradingOrderAction } from '../trading-order-monitor/grid-actions/open-entry.trading-order.action';
import type { TypedInvestorOrderGridProps } from './investor-order-monitor.widget.config';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import type { TradingOrderDetailGridProps } from './investor-order-monitor.details.grid';
import { reactivateInvestorOrderAction } from './grid-actions/reactivate.investor-order.action';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import { investorOrderMasterDetail } from './investor-order-monitor.master-detail';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { createInstrumentFollowEventHandler } from '@app/data-access/services/system/instrument-follow/instrument-follow.grid.event-handler';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import {
  TsInvestorOrdersWithFilterDocument,
  TsInvestorOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import type { InvestorOrderRow } from '@oms/generated/frontend';

export const InvestorOrderMonitorWidgetComponent = () => {
  const componentProps = useCurrentAppWidgetActorProps<TypedInvestorOrderGridProps>()[0];

  useInvestorOrderMonitorToolbarActions();

  const datasourceService = useService(TableServerDatasourceService);
  const gridProps = useVGrid<InvestorOrderRow>(
    'investor-order-monitor',
    (builder) =>
      builder
        .tableServerColumnLibrary(
          buildInvestorOrderMonitorColumnDefs(componentProps?.nonfilterable, componentProps?.excluded)
        )
        .rowSelection((c) => c.multiple())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<InvestorOrderRow, TsInvestorOrdersWithFilterSubscription>({
                query: TsInvestorOrdersWithFilterDocument,
                getData: (r) => r.tsInvestorOrdersWithFilter
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .marketData(investorOrderMarketData)
        .detailGrid<TradingOrderDetailGridProps>(investorOrderMasterDetail)
        .injectEvents([
          InvestorOrderMonitorVisibilityEventHander,
          GridConfigEventHandler,
          createInstrumentFollowEventHandler<InvestorOrderRow>({
            sourceType: 'INVESTOR_ORDER_MONITOR_GRID',
            getId: (data) => data.instrument,
            getDisplayCode: (data) => data.instrumentDisplayCode,
            colId: 'instrumentDisplayCode'
          })
        ])
        .rowStateRules({
          pending: (params) => IOPending(params.data),
          noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
        })
        .sideBar()
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(openIoEntryInvestorOrderAction('create'))
                .action(openEntryTradingOrderAction())
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(openCrossPrincipalFillAction)
                .action(openPrincipalFillAction)
                .action(investorOrderGenerateOrderSummaryAction)
                .action(investorOrderDoneForDayAction)
                .action(acceptInvestorOrderAction())
                .action(rejectInvestorOrderAction())
                .action(openRouteInvestorOrderAction())
                .action(viewInvestorOrderAction())
                .action(openIoEntryInvestorOrderAction('update'))
                .action(reactivateInvestorOrderAction)
                .action(cancelInvestorOrderAction())
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(inlineInvestorOrderActions())
                .action(refreshServersideCustomMenuAction)
            )
            .settings((s) => s.fromFile({}))
        )
        .reactiveCustomComponents(),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default InvestorOrderMonitorWidgetComponent;
