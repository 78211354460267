import type { Observable } from 'rxjs';
import { BehaviorSubject, switchMap } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { FetchPolicy } from '@apollo/client';
import { cleanMaybe, Logger } from '@oms/shared/util';
import { toGqlDatasource } from '@oms/frontend-foundation';
import { testScoped } from '@app/workspace.registry';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import {
  NotificationSettingValue,
  UpdateDefaultNotificationSettingsDocument,
  GetAllDefaultNotificationSettingsDocument,
  ResetAllNotificationSettingsDocument
} from '@oms/generated/frontend';
import type {
  DefaultNotificationSettingsFragment,
  DefaultNotificationSettingsInput,
  GetAllDefaultNotificationSettingsQuery,
  GetAllDefaultNotificationSettingsQueryVariables,
  UpdateDefaultNotificationSettingsMutation,
  UpdateDefaultNotificationSettingsMutationVariables,
  ResetAllNotificationSettingsMutation,
  ResetAllNotificationSettingsMutationVariables
} from '@oms/generated/frontend';
import type {
  MappedNotificationSettings,
  MappedDefaultNotificationSettingsInput
} from '@app/widgets/user/user-preferences-v2/preferences/notification-settings/notification-settings.contracts';

@testScoped
@singleton()
export class NotificationSettingsService {
  private fetch$ = new BehaviorSubject<void>(undefined);

  protected name: string = 'NotificationSettingsService';
  protected logger: Logger;
  protected fetchPolicy: FetchPolicy = 'cache-first';

  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse,
    @inject(AuthService) protected authService: AuthService
  ) {
    this.logger = Logger.labeled(this.name);
  }

  public triggerFetch() {
    this.fetch$.next();
  }

  public watch$(): Observable<DataSourceCommon<MappedNotificationSettings>> {
    return this.fetch$.pipe(switchMap(() => this.getAllDefaultNotificationSettings()));
  }

  public getAllDefaultNotificationSettings(): Observable<DataSourceCommon<MappedNotificationSettings>> {
    const userId = this.authService.getUserId() || '';
    return this.apolloClient
      .rxWatchQuery<GetAllDefaultNotificationSettingsQuery, GetAllDefaultNotificationSettingsQueryVariables>({
        query: GetAllDefaultNotificationSettingsDocument,
        variables: {
          userId
        },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(
        toGqlDatasource((response) => {
          const settings = cleanMaybe(
            response?.allDefaultNotificationsSettings?.settings,
            []
          ) as DefaultNotificationSettingsFragment[];
          return this.mapSettingsValuesToBoolean(settings);
        })
      );
  }

  public updateDefaultNotificationSettings(settings: MappedDefaultNotificationSettingsInput) {
    const userId = this.authService.getUserId() || '';
    const updatedSettings = this.mapSettingValueToEnum(settings);
    const mutation = this.gqlResponse.wrapMutate<
      UpdateDefaultNotificationSettingsMutation,
      UpdateDefaultNotificationSettingsMutationVariables
    >({
      mutation: UpdateDefaultNotificationSettingsDocument,
      variables: {
        settingsToUpdate: {
          ...updatedSettings,
          userId
        }
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public resetDefaultNotificationSettings() {
    const userId = this.authService.getUserId() || '';
    const mutation = this.gqlResponse.wrapMutate<
      ResetAllNotificationSettingsMutation,
      ResetAllNotificationSettingsMutationVariables
    >({
      mutation: ResetAllNotificationSettingsDocument,
      variables: {
        userId
      },
      refetchQueries: [GetAllDefaultNotificationSettingsDocument]
    });

    return mutation.exec();
  }

  private mapSettingsValuesToBoolean(
    settings: DefaultNotificationSettingsFragment[]
  ): MappedNotificationSettings[] {
    return settings.map((setting) => ({
      notificationName: setting.notificationName,
      isPopup: setting?.isPopup === NotificationSettingValue.Enabled,
      isShown: setting?.isShown === NotificationSettingValue.Enabled,
      isSound: setting?.isSound === NotificationSettingValue.Enabled
    }));
  }

  private mapSettingValueToEnum(
    settings: MappedDefaultNotificationSettingsInput
  ): Pick<DefaultNotificationSettingsInput, 'notificationName' | 'isPopup' | 'isShown' | 'isSound'> {
    return Object.keys(settings).reduce(
      (acc, key) => {
        if (key === 'notificationName') {
          acc.notificationName = settings.notificationName;
        } else if (key === 'isPopup' || key === 'isShown' || key === 'isSound') {
          acc[key] =
            settings[key as keyof MappedNotificationSettings] === true
              ? NotificationSettingValue.Enabled
              : NotificationSettingValue.Disabled;
        }
        return acc;
      },
      {} as Pick<DefaultNotificationSettingsInput, 'notificationName' | 'isPopup' | 'isShown' | 'isSound'>
    );
  }
}
