import { InvestorOrderStatus, TradingOrderStatus } from '@oms/generated/frontend';

type StateRulesUtilArgs = { status?: string | null; executedQuantity?: number } | undefined | null;

// Determines if the given IO row should have the "Pending" row state.
export function IOPending(args: StateRulesUtilArgs) {
  const { status } = args || {};
  return (
    status === InvestorOrderStatus.CancellationPending ||
    status === InvestorOrderStatus.ModificationPending ||
    status === InvestorOrderStatus.Unaccepted ||
    status === InvestorOrderStatus.ExpiryPending
  );
}

// Determines if the given IO row should have the "No Executed Quantity" row state.
export function IONoExecutedQuantity(args: StateRulesUtilArgs) {
  const { status, executedQuantity } = args || {};
  return (
    executedQuantity === 0 &&
    (status === InvestorOrderStatus.Cancelled ||
      status === InvestorOrderStatus.Expired ||
      status === InvestorOrderStatus.Inactive ||
      status === InvestorOrderStatus.ExecutionCancelled)
  );
}

// Determines if the given IO row should have the "Has Executed Quantity" row state.
export function IOHasExecutedQuantity(args: StateRulesUtilArgs) {
  const { status, executedQuantity } = args || {};
  return (
    typeof executedQuantity === 'number' &&
    executedQuantity > 0 &&
    (status === InvestorOrderStatus.Cancelled ||
      status === InvestorOrderStatus.Expired ||
      status === InvestorOrderStatus.Inactive ||
      status === InvestorOrderStatus.FullyFilled ||
      status === InvestorOrderStatus.ExecutionCancelled)
  );
}

// Determines if the given TO row should have the "Pending" row state.
export function TOPending(args: StateRulesUtilArgs) {
  const { status, executedQuantity } = args || {};
  return (
    status === TradingOrderStatus.PendingCancel ||
    status === TradingOrderStatus.ModifyRequested ||
    status === TradingOrderStatus.ModifyPending ||
    status === TradingOrderStatus.ExpiryRequested ||
    status === TradingOrderStatus.ExpiryPending ||
    (status === TradingOrderStatus.RouteRequested && executedQuantity === 0)
  );
}

// Determines if the given TO row should have the "No Executed Quantity" row state.
export function TONoExecutedQuantity(args: StateRulesUtilArgs) {
  const { status, executedQuantity } = args || {};
  return (
    status === TradingOrderStatus.Rejected ||
    (executedQuantity === 0 &&
      (status === TradingOrderStatus.Inactive ||
        status === TradingOrderStatus.Expired ||
        status === TradingOrderStatus.Cancelled ||
        status === TradingOrderStatus.TradeCancelled))
  );
}

// Determines if the given TO row should have the "Has Executed Quantity" row state.
export function TOHasExecutedQuantity(args: StateRulesUtilArgs): boolean {
  const { status, executedQuantity } = args || {};
  return (
    typeof executedQuantity === 'number' &&
    executedQuantity > 0 &&
    (status === TradingOrderStatus.Inactive ||
      status === TradingOrderStatus.Expired ||
      status === TradingOrderStatus.FullyFilled ||
      status === TradingOrderStatus.Cancelled ||
      status === TradingOrderStatus.TradeCancelled)
  );
}
