import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import type { MappedInvestorOrderRow } from '@oms/generated/frontend';

const tradingOrderInvestorOrdersDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

export const tradingOrderInvestorOrdersColumns: ColDefMap<MappedInvestorOrderRow> = {
  id: { extends: 'orders.id', hide: true },
  limitPrice: { extends: 'orders.limitPrice' },
  side: { extends: 'orders.side' },
  locate: { extends: 'generic.text' },
  totalQuantity: { extends: 'orders.quantity', width: 110 },
  mappedQuantity: { extends: 'orders.quantity' },
  executedQuantity: { extends: 'orders.quantity' },
  todayAveragePrice: { extends: 'orders.price' },
  todayExecutedQuantity: { extends: 'orders.todayExecutedQuantity' },
  averagePrice: { extends: 'orders.price' },
  createdTimestamp: { extends: 'generic.timestamp' }
};

const tradingOrderInvestorOrdersColumnLibrary: TableServerColumnLibrary<MappedInvestorOrderRow> = {
  defaultColumn: tradingOrderInvestorOrdersDefaultColumn,
  columns: tradingOrderInvestorOrdersColumns
};

export const buildInvestorOrderViewTradingOrdersColumnDefs = () =>
  buildColumnDefs(tradingOrderInvestorOrdersColumnLibrary, 'tradingOrderViewInvestorOrders');
