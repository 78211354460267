import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Level1IntegrationEvent } from '@oms/generated/frontend';
import { BuilderCallback, Paths } from '@oms/shared/util-types';
import { isFunction } from 'lodash';
import { ColumnBuilder } from './column.builder';
import { BaseColumnGroupBuilder } from './column.group.builder';
import { AnyRecord } from '@oms/frontend-foundation';

export type MarketDataField<TData extends AnyRecord> =
  | keyof Level1IntegrationEvent
  | ((builder: ColumnBuilder<TData>) => ColumnBuilder<TData>);

/**
 * Configuration for choosing market data fields with overrides.
 */
export interface MarketDataFieldConfig<TData extends AnyRecord> {
  /**
   * When true, the provided fields override the default column defs for that field & all other fields are added as column defs with the defaults.
   * When false, only the provided fields are used.
   * Defaults to true.
   */
  useAllFields?: boolean;
  /**
   * What field in your grid contains the symbol or ticker for loading market data.
   */
  tickerId: keyof TData | Paths<TData, 3>;

  /**
   * What market data fields you want to include in your grid. The strategy determines if other fields are added too.
   */
  fields?: MarketDataField<TData>[];
}

type MarketDataColumnMap<TData extends AnyRecord> = Partial<
  Record<keyof Level1IntegrationEvent, BuilderCallback<ColumnBuilder<TData>>>
>;

export interface MarketDataColDef<TData> extends ColGroupDef<TData> {
  grouped?: boolean;
}

export class Level1MarketDataGroupBuilder<TData extends AnyRecord> extends BaseColumnGroupBuilder<
  TData,
  Level1MarketDataGroupBuilder<TData>,
  MarketDataColDef<TData>
> {
  private _defaultField: (builder: ColumnBuilder<TData>) => ColumnBuilder<TData> = (b) =>
    b.minWidth(100).width(120);

  protected create(): Level1MarketDataGroupBuilder<TData> {
    return new Level1MarketDataGroupBuilder<TData>();
  }

  protected get instance() {
    return this;
  }

  /***
   * Map Level1IntegrationEvent properties to column fields
   *
   * Naming convention: all Date and DateTime fields have DT suffix
   * Price Multiplier and Opening Auction Volume removed in PR #6450
   * Not in use:
   * - bidDirection: (col) => col.header('Bid Tick').shortHeader('BidTick')
   * - propertyIds: (col) => col.header("propertyIds").shortHeader("propertyIds")
   * - shortSellRestricted: (col) => col.header('SSR').shortHeader('SSR')
   *
   */
  private static _fieldMap: MarketDataColumnMap<AnyRecord> = {
    // POC
    preTradingDateTime: (col) => col.header('PreTrading Datetime').format('date'),
    preTradingPrice: (col) => col.header('PreTrading Price'),
    postTradingDateTime: (col) => col.header('PostTrading Datetime').format('date'),
    postTradingPrice: (col) => col.header('PostTrading Price'),

    adv30day: (col) => col.header('ADV30').format('quantity'),
    adv5day: (col) => col.header('ADV5').format('quantity'),
    askDeleted: (col) => col.header('Ask Deleted').shortHeader('AskDel').bool('yesNo'),
    askExchange: (col) => col.header('Ask Exch').shortHeader('AskExch'),
    askPrice: (col) => col.header('Ask'),
    askPriceDateTime: (col) => col.header('Ask Datetime').shortHeader('AskDT').format('datetime'),
    askSize: (col) => col.header('Ask Size').shortHeader('AskSz').format('quantity'),
    bidDeleted: (col) => col.header('Bid Deleted').shortHeader('BidDel').bool('yesNo'),
    bidExchange: (col) => col.header('Bid Exch').shortHeader('BidExch'),
    bidPrice: (col) => col.header('Bid'),
    bidPriceDateTime: (col) => col.header('Bid Datetime').shortHeader('BidDT').format('datetime'),
    bidSize: (col) => col.header('Bid Size').shortHeader('BidSz').format('quantity'),
    caveatEmptor: (col) => col.header('Caveat Emptor').shortHeader('Caveat').bool('yesNo'),
    closeDateTime: (col) => col.header('Close Datetime').shortHeader('ClsDT').format('datetime'),
    closePrice: (col) => col.header('Close Price').shortHeader('Cls'),
    closeVolume: (col) => col.header('Close Vol').shortHeader('ClsVol').format('quantity'),
    closingAskQuotePrice: (col) => col.header('Close Ask').shortHeader('ClsAsk'),
    closingAskVolume: (col) => col.header('Close Ask Vol').shortHeader('ClsAskVol').format('quantity'),
    closingAuctionVolume: (col) =>
      col.header('Closing Auction Vol').shortHeader('ClsAuctVol').format('quantity'),
    closingBidQuotePrice: (col) => col.header('Close Bid').shortHeader('ClsBid'),
    closingBidVolume: (col) => col.header('Close Bid Vol').shortHeader('ClsBidVol').format('quantity'),
    cumulativeVolume: (col) => col.header('Cumulative Volume').shortHeader('Vol').format('quantity'),
    high52weekDate: (col) => col.header('52 High Date').shortHeader('52HighDT').format('date'),
    high52weekPrice: (col) => col.header('52 High').shortHeader('52High'),
    highDayPrice: (col) => col.header('Day High').shortHeader('High'),
    highYtdDate: (col) => col.header('High YTD Date').shortHeader('YTDHighDT').format('date'),
    highYtdPrice: (col) => col.header('YTD High').shortHeader('YTDHigh'),
    imbalance: (col) => col.header('Imbalance').shortHeader('Imb').format('quantity'),
    isMarketOpen: (col) => col.header('Mkt Open').shortHeader('MktOpen').bool('yesNo'),
    isSuspended: (col) => col.header('Is Suspended').shortHeader('Susp').bool('yesNo'),
    lastTradeDateTime: (col) => col.header('Last Trd Datetime').shortHeader('LastDT').format('datetime'),
    lastTradeExchange: (col) => col.header('Last Trd Exch').shortHeader('LastExch'),
    lastTradePrice: (col) => col.header('Last Trd').shortHeader('Last'),
    lastTradeSize: (col) => col.header('Last Trd Size').shortHeader('LastSz').format('quantity'),
    latestAuctionDateTime: (col) => col.header('Latest Auction DT').shortHeader('LADT').format('datetime'),
    latestAuctionPrice: (col) => col.header('Latest Auction Price').shortHeader('LAP'),
    latestAuctionVolume: (col) => col.header('Latest Auction Vol').shortHeader('LAV').format('quantity'),
    limitDownPrice: (col) => col.header('Limit Down Price').shortHeader('LD'),
    limitUpPrice: (col) => col.header('Limit Up Price').shortHeader('LU'),
    lotSize: (col) => col.header('Lot Size').shortHeader('LotSz'),
    low52weekDate: (col) => col.header('52 Low Date').shortHeader('52LowDT').format('date'),
    low52weekPrice: (col) => col.header('52 Low').shortHeader('52Low'),
    lowDayPrice: (col) => col.header('Day Low').shortHeader('Low'),
    lowYtdDate: (col) => col.header('Low YTD Date').shortHeader('YTDLowDT').format('date'),
    lowYtdPrice: (col) => col.header('YTD Low').shortHeader('YTDLow'),
    marketPhase: (col) => col.header('Mkt Phase').shortHeader('Phase'),
    marketSegment: (col) => col.header('Market Segment').shortHeader('Segment'),
    midPrice: (col) => col.header('Mid Price').shortHeader('Mid'),
    openDateTime: (col) => col.header('Open Datetime').shortHeader('OpnDT').format('datetime'),
    openingAuctionDateTime: (col) => col.header('Open Auction DT').shortHeader('OaDT').format('datetime'),
    openingAuctionPrice: (col) => col.header('Open Auction Price').shortHeader('openingAuctionPrice'),
    openPrice: (col) => col.header('Open Price').shortHeader('Opn'),
    pqeFlag: (col) => col.header('PQE Flag').shortHeader('PQE').bool('yesNo'),
    previousCloseDateTime: (col) =>
      col.header('Prev Close Datetime').shortHeader('PrevClsDT').format('datetime'),
    previousClosePrice: (col) => col.header('Prev Close').shortHeader('PrevCls'),
    priceChange: (col) => col.header('Change').shortHeader('Chng'),
    priceChangePercent: (col) => col.header('% Change').shortHeader('%Chng'),
    pricingCurrency: (col) => col.header('Price Currency').shortHeader('PxCcy'),
    shortSellRestricted: (col) => col.header('SSR').shortHeader('SSR'),
    tickSize: (col) => col.header('Tick Size').shortHeader('TickSz'),
    tradingStatus: (col) => col.header('Trading Status').shortHeader('TrdStatus'),
    tradeTickDirection: (col) => col.header('Trade Tick').shortHeader('Trd Tick'),
    lastTradeCondition: (col) => col.header('Last Trd Cond').shortHeader('Last Cond'),
    vwap: (col) => col.header('VWAP')
  };

  public defaultField(
    b: (builder: ColumnBuilder<TData>) => ColumnBuilder<TData>
  ): Level1MarketDataGroupBuilder<TData> {
    this._defaultField = b;
    return this;
  }

  public fields(config: MarketDataFieldConfig<TData>): Level1MarketDataGroupBuilder<TData> {
    const { tickerId, useAllFields = true, fields: fieldsConfig = [] } = config;
    const marketDataFieldNames = Object.keys(Level1MarketDataGroupBuilder._fieldMap);
    const fields = fieldsConfig.length
      ? fieldsConfig
      : (marketDataFieldNames as (keyof Level1IntegrationEvent)[]);

    fields.forEach((f) => {
      this.column((c) => {
        c.colDef(this._defaultField(c).build() as ColDef<TData>);
        const colOverride = isFunction(f) ? f(c).build() : undefined;
        const fieldName = (colOverride ? colOverride.field : f) as keyof Level1IntegrationEvent;
        const field = Level1MarketDataGroupBuilder._fieldMap[fieldName] as
          | BuilderCallback<ColumnBuilder<TData>>
          | undefined;

        let colDef: ColDef<TData> = {};

        if (field) {
          const columnBuilder = field(c);
          colDef = columnBuilder.build();
        }

        c.colDef(colDef)
          .level1MarketData(fieldName, tickerId)
          .colDef(colOverride || {});

        return c;
      });
    });

    if (fieldsConfig.length && useAllFields) {
      const existingFieldNames: Record<string, boolean> = {};
      this._colGroupDef.children.forEach((c: ColDef<TData>) => {
        if (c.field) {
          existingFieldNames[c.field] = true;
        }
      });

      marketDataFieldNames.forEach((f) => {
        if (!existingFieldNames[f]) {
          const fieldMapConfig = Level1MarketDataGroupBuilder._fieldMap[f as keyof Level1IntegrationEvent] as
            | BuilderCallback<ColumnBuilder<TData>>
            | undefined;

          const defaultColDefFn = (c: ColumnBuilder<TData>) => this._defaultField(c).build();
          let colDefFn: (c: ColumnBuilder<TData>) => ColDef<TData> = () => ({}) as ColDef<TData>;
          if (fieldMapConfig) {
            colDefFn = (c: ColumnBuilder<TData>) => fieldMapConfig(c).build();
          }

          this.column((c) =>
            c
              .colDef(defaultColDefFn(c))
              .level1MarketData(f as keyof Level1IntegrationEvent, tickerId)
              .colDef(colDefFn(c))
          );
        }
      });
    }

    return this;
  }

  public grouped(isGrouped?: boolean): Level1MarketDataGroupBuilder<TData> {
    this._colGroupDef.grouped = isGrouped === undefined || isGrouped;
    return this;
  }

  public override build(): MarketDataColDef<TData> {
    return this._colGroupDef;
  }
}
