import type { MarketDataBuilder } from '@oms/frontend-vgrid';
import type {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import type { TradingOrderRow } from '@oms/generated/frontend';
import { MarketDataCellRenderer } from '@app/data-access/services/marketdata/components/market-data.cell.renderer';

const tradingOrderMonitorDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: true
};

export const tradingOrderMonitorColumns: ColDefMap<TradingOrderRow> = {
  id: { extends: 'orders.id' },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  instrumentLongName: { extends: 'orders.instrumentLongName' },
  instrumentCusip: { extends: 'orders.instrumentCusip' },
  instrumentExchCode: { extends: 'orders.instrumentExchCode' },
  side: { extends: 'orders.side' },
  locate: { extends: 'generic.text' },
  limitPrice: { extends: 'orders.limitPrice' },
  orderType: { extends: 'orders.orderType' },
  capacity: { extends: 'tradingOrders.capacity' },
  totalQuantity: { extends: 'orders.quantity' },
  leavesQuantity: { extends: 'orders.quantity' },
  executedQuantity: { extends: 'orders.quantity', cellRenderer: 'ProgressRenderer' },
  settleCurrency: { extends: 'generic.currency', hide: true },
  settleType: { extends: 'orders.settleType', hide: true },
  settleDate: {
    extends: 'generic.date',
    minWidth: 160,
    hide: true
  },
  tradingAccountName: { extends: 'orders.investorAccount', floatingFilter: true },
  venue: { extends: 'generic.text' },
  targetExchangeId: { extends: 'generic.text' },
  timeInForce: { extends: 'orders.timeInForce' },
  tifDuration: { extends: 'generic.numeric' },
  expiryDateTime: {
    extends: 'generic.dateTime',
    hide: true
  },
  enteredByName: { extends: 'generic.text', width: 180, floatingFilter: true },
  status: {
    extends: 'tradingOrders.status'
  },
  orderTagIds: {
    width: 110,
    filter: false,
    sortable: false,
    hide: true
  },
  averagePrice: { extends: 'orders.price' },
  createdTimestamp: { extends: 'generic.timestamp', sort: 'desc' },
  updatedTimestamp: { extends: 'generic.timestamp', sort: 'desc' },
  orderVisibilityReason: {
    extends: 'orders.visibilityReason',
    hide: true
  },
  ownerName: {
    extends: 'orders.owner',
    hide: true
  },
  ownerID: {
    extends: 'orders.ownerId',
    hide: true
  },
  tradeCurrency: { extends: 'generic.currency', hide: true },
  customerNotes: { extends: 'generic.text', hide: true },
  representativeCode: { extends: 'generic.text', hide: true },
  category: {
    extends: 'tradingOrders.category'
  }
};

const tradingOrderMonitorColumnLibrary: TableServerColumnLibrary<TradingOrderRow> = {
  defaultColumn: tradingOrderMonitorDefaultColumn,
  columns: tradingOrderMonitorColumns
};

export const buildTradingOrderMonitorColumnDefs = () =>
  buildColumnDefs(tradingOrderMonitorColumnLibrary, 'tradingOrderMonitor');

const investorOrderTradingOrderMonitorDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  enablePivot: true,
  enableRowGroup: true,
  filter: false,
  floatingFilter: false
};

const investorOrderTradingOrderMonitorColumns: ColDefMap<TradingOrderRow> = {
  id: { extends: 'orders.id', hide: true },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  instrumentLongName: { extends: 'orders.instrumentLongName' },
  instrumentCusip: { extends: 'orders.instrumentCusip' },
  instrumentExchCode: { extends: 'orders.instrumentExchCode' },
  side: { extends: 'orders.side' },
  locate: { extends: 'generic.text' },
  limitPrice: { extends: 'orders.limitPrice' },
  orderType: { extends: 'orders.orderType' },
  // mappedQuantity: { extends: 'orders.quantity' },
  totalQuantity: { extends: 'orders.quantity' },
  tifDuration: { extends: 'generic.numeric' },
  leavesQuantity: { extends: 'orders.quantity' },
  executedQuantity: { extends: 'orders.quantity', cellRenderer: 'ProgressRenderer' },
  settleCurrency: { extends: 'generic.currency', hide: true },
  settleType: { extends: 'orders.settleType', hide: true },
  settleDate: {
    extends: 'generic.date',
    minWidth: 160,
    hide: true
  },
  tradingAccountName: { extends: 'orders.investorAccount' },
  venue: { extends: 'generic.text' },
  category: {
    extends: 'tradingOrders.category'
  },
  timeInForce: { extends: 'orders.timeInForce' },
  enteredByName: { extends: 'generic.text', width: 180 },
  status: {
    extends: 'tradingOrders.status'
  },
  averagePrice: { extends: 'orders.price' },
  createdTimestamp: { extends: 'generic.timestamp', sort: 'desc' },
  updatedTimestamp: { extends: 'generic.timestamp', sort: 'desc' },
  orderVisibilityReason: {
    extends: 'orders.visibilityReason',
    hide: true
  }
};

const investorOrderTradingOrderMonitorColumnLibrary: TableServerColumnLibrary<TradingOrderRow> = {
  defaultColumn: investorOrderTradingOrderMonitorDefaultColumn,
  columns: investorOrderTradingOrderMonitorColumns
};

export const buildInvestorOrderTradingOrderMonitorColumnDefs = () =>
  buildColumnDefs(investorOrderTradingOrderMonitorColumnLibrary, 'investorOrderTradingOrderMonitor');

export const tradingOrderMarketData = (m: MarketDataBuilder<TradingOrderRow & { ticker?: string }>) =>
  m.level1((l) =>
    l
      .defaultField((c) =>
        c
          .minWidth(90)
          .width(100)
          .hide()
          .cell((c) => c.renderer(MarketDataCellRenderer))
      )
      .fields({
        tickerId: 'instrumentDisplayCode', // the ticker field to "join" with
        fields: [
          (c) => c.field('bidPrice').show().level1MarketData('bidPrice', 'ticker').filter(false),
          (c) => c.field('askPrice').show().level1MarketData('askPrice', 'ticker').filter(false)
        ]
      })
  );
